import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import Modal from '../common/Modal';
import Checkbox from '../common/Checkbox';

import { ReactComponent as Box } from '../../assets/svgs/dialog-box.svg';
import { ReactComponent as Cross } from '../../assets/svgs/cross.svg';
import { ReactComponent as Lamp } from '../../assets/svgs/lamp.svg';

import Input from '../common/Input';
import Button from '../common/Button';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';
import { MEDIA_MAX_MOBILE_VERTICAL_MAX } from '../../constants/media';

export interface SubscribeModalProps {
  onSubmit: ({ email, nickname }: {email: string, nickname: string}) => void;
  onClose: () => void;
}

const validateEmail = (value: string) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/gi.test(value);

function SubscribeModal({ onSubmit, onClose }: SubscribeModalProps) {
  const [email, setEmail, isValidEmail] = useInput('', {
    parser: value => value.trim(),
    validator: value => value.length > 0 && validateEmail(value),
  });

  const [nickname, setNickname, isValidNickname] = useInput('', {
    parser: value => value.trimStart(),
    validator: value => value.length > 0,
  });

  const [isAgreeInfo, toggleAgreeInfo] = useToggle(false);

  const [isAgreeMarketing, toggleAgreeMarketing] = useToggle(false);

  const isCanSubmit = useMemo(
    () => isValidEmail && isValidNickname && isAgreeInfo && isAgreeMarketing,
    [isValidEmail, isValidNickname, isAgreeInfo, isAgreeMarketing],
  );

  const handleSubmit = useCallback(() => {
    if (isCanSubmit === false) return;

    onSubmit({ email, nickname });
  }, [isCanSubmit, email, nickname, onSubmit]);

  return (
    <Modal open onClose={onClose}>
      <Wrapper>
        <Contents>
          <CrossArea>
            <Cross onClick={onClose} />
          </CrossArea>
          <StyledLamp />
          <Title>Join mailing list</Title>
          <Description>
            Please fill out the fields below to get notified of future updates and other news about Feather.
          </Description>
          <StyledInput
            placeholder="Email address"
            valid={isValidEmail}
            value={email}
            onChange={setEmail}
          />
          <StyledInput
            placeholder="What would you like to be called?"
            valid={isValidNickname}
            value={nickname}
            onChange={setNickname}
          />
          <CheckboxLabel>
            <Checkbox checked={isAgreeInfo} onToggle={toggleAgreeInfo} />
            <p>
              I agree to collection and use of personal information
              {' '}
              (email address and nickname)
              {' '}
              for sending emails only.
            </p>
          </CheckboxLabel>
          <CheckboxLabel>
            <Checkbox checked={isAgreeMarketing} onToggle={toggleAgreeMarketing} />
            <p>
              I agree to recieve marketing information such as release announcements, update news, and/or promotions.
            </p>
          </CheckboxLabel>
          <StyledButton disabled={isCanSubmit === false} onClick={handleSubmit}>Submit</StyledButton>
        </Contents>
        <StyledBox />
      </Wrapper>
    </Modal>
  );
}

export default SubscribeModal;

const Wrapper = styled.section`
  position: relative;
  max-width: 530px;
  margin: 1.5rem;
`;

const StyledBox = styled(Box)`
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CrossArea = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  & > svg {
    fill: none;
    stroke: ${({ theme }) => theme.color};
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Contents = styled.div`
  padding: 3rem 3rem 3rem;
`;

const StyledLamp = styled(Lamp)`
  fill: none;
  display: block;
  margin: 0 auto 1.5rem;
`;

const Title = styled.h4`
  font-weight: 500;
  font-size: 2rem;
  line-height: 150%;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1.125rem;
  text-align: center;
  margin: 0.5rem 0.5rem 2rem;
`;

const StyledInput = styled(Input)`
  margin-bottom: 1.5rem;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  line-height: 124%;

  & > label {
    flex-shrink: 0;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
  width: 238px;
  height: 80px;
  margin-top: 2.5rem;
  font-size: 1.5rem;

  color: ${({ theme }) => theme.color};

  & > svg {
    fill: ${({ theme }) => theme.background};
    & path[class='background'] {
      fill: ${({ theme }) => theme.color};
    }
  }

  &:disabled {
    opacity: 0.3;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    width: 180px;
    height: 60px;
  }
`;
