import React from 'react';
import styled from 'styled-components';
import { MEDIA_MAX_MOBILE_HORIZONTAL_MAX, MEDIA_MAX_MOBILE_VERTICAL_MAX } from '../../constants/media';

export interface ChangeNoticeProps {
  onSubscribe: () => void;
  className?: string;
}
function ChangeNotice({ onSubscribe, className }: ChangeNoticeProps) {
  return (
    <Wrapper className={className}>
      <p>Note: Some of plans and features above are in development and subject to change.</p>
    </Wrapper>
  );
}

export default ChangeNotice;

const Wrapper = styled.section`
  & > p {
    font-weight: 600;
    text-align: center;
    font-size: 0.875rem;
    line-height: 130%;
    letter-spacing: -0.02em;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    padding: 3rem;
    font-sisz: 1.125rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 1rem;
  }
`;

const Mailing = styled.span`
  display: inline-block;
  cursor: pointer;
  border-bottom: 1px solid black;
`;
