/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

export interface SeeMoreProps {
  className?: string;
  onSeeMore: () => void;
}

function TermsContents() {
  return (
    <Wrapper>
      <StyledP>
        Your sketches are yours, the software is ours. Your are free to use your sketches for business or personal purposes. We will continue to develop new features and updates to help your sketch. This can sometimes lead to bugs, crashes, and data corruption and loss (please let us know it happens by sending email to
        <StyledA href="mailto:support@sketchsoft3d.com">&nbsp;support@sketchsoft3d.com</StyledA>
        . We collect usage statistics to provide you with better products and services. Please read our PRIVACY POLICY for more details. Please do not violate our TERMS OF SERVICE or any other laws, including copyrights laws.
      </StyledP>
      <StyledP>By installing, downloading, accessing, copying, or using all or any part of the Services provided by Feather or Sketchsoft, or, indicating your acceptance of this AGREEMENT by selecting (&quot;Sign up&quot;); (a) you accept this AGREEMENT on behalf of any entity authorized to act by you and that such entity is legally bound by this AGREEMENT; (b) you represent and warrant that you have the right and authority to act and bind on behalf of such entity (if any) or yourself; and (c) you represent and warrant that you have the right and authority to act and bind on your behalf or on any such entity (if any); and (c) you represent and warrant that you are above 16 years of age. You may not accept this AGREEMENT onbehalf on any other entity unless you are an employee or agent of of that entity who has the right, power and authority to act on behalf of that entity.</StyledP>
      <StyledP>All capitalized words in this AGREEMENT are constructed with reference to their definitions in [1] Definition.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[1] Definitions</StyledH3>
      <StyledP>The following terms are used regularly throughout these TERMS OF SERVICE and has a particular meaning:</StyledP>
      <StyledNum>
        1.
        <StyledLabel>&nbsp;&nbsp;ACCESS CREDENTIAL&nbsp;</StyledLabel>
        : means of any user name, identification number, password, PIN, other security codes, or devices used, and may be individually or in combination, to verify an individual&apos;s identity or to certificate for access and use the Software.
      </StyledNum>
      <StyledNum>
        2.
        <StyledLabel>&nbsp;&nbsp;ACCOUNT&nbsp;</StyledLabel>
        : means a registered account with FEATHER.
      </StyledNum>
      <StyledNum>
        3.
        <StyledLabel>&nbsp;&nbsp;AGREEMENT&nbsp;</StyledLabel>
        : means the agreement between the USER and the SKETCHSOFT through these TERMS OF SERVICE.
      </StyledNum>
      <StyledNum>
        4.
        <StyledLabel>&nbsp;&nbsp;ANONYMIZED&nbsp;</StyledLabel>
        : means anonymized data that cannot directly or indirectly identify any individual or entity through the data by SKETCHSOFT or any third party.
      </StyledNum>
      <StyledNum>
        5.
        <StyledLabel>&nbsp;&nbsp;AUTHORIZED USER&nbsp;</StyledLabel>
        : means personal users, employees, independent contractors, or agents who are authorized by SKETCHSOFT to access and use the FEATHER or SOFTWARE under the rights outlined in these TERMS OF SERVICE.
      </StyledNum>
      <StyledNum>
        6.
        <StyledLabel>&nbsp;&nbsp;BOOK&nbsp;</StyledLabel>
        : means the space within FEATHER that contains USER notes and folders.
      </StyledNum>
      <StyledNum>
        7.
        <StyledLabel>&nbsp;&nbsp;BUSINESS DAY&nbsp;</StyledLabel>
        : means a day (other than a Saturday, Sunday or public holiday) on which banks are open for general banking business in Seoul, Republic of Korea.
      </StyledNum>
      <StyledNum>
        8.
        <StyledLabel>&nbsp;&nbsp;CLAIM&nbsp;</StyledLabel>
        : means any third-party claim, suit, action, or proceeding.
      </StyledNum>
      <StyledNum>
        9.
        <StyledLabel>&nbsp;&nbsp;COMPANY&nbsp;</StyledLabel>
        : means SKETCHSOFT.
      </StyledNum>
      <StyledDoubleDigitNum>
        10.
        <StyledLabel>&nbsp;&nbsp;CURVE&nbsp;</StyledLabel>
        : means that it is created in 3D space by USER&apos;s pen input.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        11.
        <StyledLabel>&nbsp;&nbsp;DOCUMENTATION&nbsp;</StyledLabel>
        : means the manuals, product literature, instructions, schematics, or any other documentations prepared or published by SKETCHSOFT that describe or relate to the SKETCHSOFT TECHNOLOGY and its use, operation, features, functionality and capabilities.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        12.
        <StyledLabel>&nbsp;&nbsp;EFFECTIVE DATE&nbsp;</StyledLabel>
        : means the earlier of the date USER installs, downloads, accesses, or otherwise copies or uses the FEATHER and/or SOFTWARE in whole or in part.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        13.
        <StyledLabel>&nbsp;&nbsp;FEATHER&nbsp;</StyledLabel>
        : means the FEATHER digital platform which may be used to create and/or organize 2-3 dimensional or 3-dimensional designs and concepts which is accessible from the SITE and/or application.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        14.
        <StyledLabel>&nbsp;&nbsp;FEATHER DATA&nbsp;</StyledLabel>
        : means any softwares, services, data, documentations, media, information or all other content that are provided or used by Sketchsoft in connection with the FEATHER, SOFTWARE or the SERVICES including any information, data, or other content derived from SKETCHSOFT&apos;s monitoring of USER&apos;s access to or use of the SOFTWARE or the SERVICES, but excluding any USER CONTENT.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        15.
        <StyledLabel>&nbsp;&nbsp;FEATHER PLATFORM&nbsp;</StyledLabel>
        : means SOFTWARE as a service operated and/or licensed by SKETCHSOFT which may be used to review, and/or communicate 2-3 dimensional or 3-dimensional designs and concepts, together with any FEATHER DATA comprised therein or accessed thereby. Here is our open source list what we used.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        16.
        <StyledLabel>&nbsp;&nbsp;FEE&nbsp;</StyledLabel>
        : means a fee charged by the SKETCHSOFT for use of FEATHER.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        17.
        <StyledLabel>&nbsp;&nbsp;FEEDBACK&nbsp;</StyledLabel>
        : any comments, feedback, potential errors and improvements, reports, and ideas about the SKETCHSOFT TECHNOLOGY that Customer may provide to SKETCHSOFT
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        18.
        <StyledLabel>&nbsp;&nbsp;INTELLECTUAL PROPERTY&nbsp;</StyledLabel>
        : means all copyright, patents, inventions, trade secrets, know-how, product formulations, designs, circuit layouts, databases, registered or unregistered trademarks, brand names, business names, domain names and other forms of intellectual property in each case whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        19.
        <StyledLabel>&nbsp;&nbsp;LOSES&nbsp;</StyledLabel>
        : means any losses, damages, liabilities, costs, claims, charges, demands, actions, proceedings and expenses (including legal and professional fees and disbursements and costs of investigation, litigation, settlement, judgment, interest and penalties).
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        20.
        <StyledLabel>&nbsp;&nbsp;NOTE&nbsp;</StyledLabel>
        : means the space in FEATHER where the USER&apos;s sketch takes palces.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        21.
        <StyledLabel>&nbsp;&nbsp;ORGANIZATION&nbsp;</StyledLabel>
        : discrete AUTHORIZED USER groups set up for a given company or team, who may collaborate with each other and view and/or access files stored within the group.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        22.
        <StyledLabel>&nbsp;&nbsp;PRIVACY POLICY&nbsp;</StyledLabel>
        : means the SKETCHSOFT&apos;s
        <StyledA href="/privacy">&nbsp;privacy policy</StyledA>
        , which is updated from time to time, and can be found on the SITE.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        23.
        <StyledLabel>&nbsp;&nbsp;RESULT&nbsp;</StyledLabel>
        : means any content generated during the operation of the FEATHER.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        24.
        <StyledLabel>&nbsp;&nbsp;SKETCHSOFT&nbsp;</StyledLabel>
        : means Sketchsoft, a company incorporated and registered in Seoul, Republic of Korea whose registered office is at #201, 168, Yeoksam-ro, Gangnam-gu, Seoul, Republic of Korea.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        25.
        <StyledLabel>&nbsp;&nbsp;SKETCHSOFT TECHNOLOGY&nbsp;</StyledLabel>
        : means the the FEATHER, DOCUMENTATION, any website and applications or SOFTWARE together with any and all technology and software owned by SKETCHSOFT associated processes, materials, tools, and business methods.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        26.
        <StyledLabel>&nbsp;&nbsp;SITE&nbsp;</StyledLabel>
        : means the SKETCHSOFT&apos;s website found at
        <StyledA href="https://www.sketchsoft3d.com/">&nbsp;https://www.sketchsoft3d.com/</StyledA>
        , and&nbsp;
        <StyledA href="https://feather.art/">https://feather.art/</StyledA>
        &nbsp;or such other URL the COMPANY may adopt from time to time.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        27.
        <StyledLabel>&nbsp;&nbsp;SOFTWARE&nbsp;</StyledLabel>
        : means the SKETCHSOFT software product(s) (including without limitation, FEATHER) provided in connection with these TERMS OF SERVICE, including any Updates and/or added functionality. The SOFTWARE offerings can be found here, which may be subject to change.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        28.
        <StyledLabel>&nbsp;&nbsp;SUBSCRIPTION FEE&nbsp;</StyledLabel>
        : means a FEE charged by the SKETCHSOFT for use of the FEATHER, SOFTWARE or other services on a periodical basis.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        29.
        <StyledLabel>&nbsp;&nbsp;TPS&nbsp;</StyledLabel>
        : means an online third party service provider with whom a USER holds an ACCOUNT.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        30.
        <StyledLabel>&nbsp;&nbsp;USAGE DATA&nbsp;</StyledLabel>
        : means any ANONYMIZED and aggregated data that is derived from the usage of the FEATHER and/or SKETCHSOFT TECHNOLOGY, including but not limited to organization ID, username, login IP address, login and log out times, crash reports, file type, file size, tool usage and any statistical or other analysis information, or data based on or derived from the usage.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        31.
        <StyledLabel>&nbsp;&nbsp;USER&nbsp;</StyledLabel>
        : means any registered person or company of FEATHER and/or FEATHER PLATFORM.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        32.
        <StyledLabel>&nbsp;&nbsp;USER CONTENT&nbsp;</StyledLabel>
        : means BOOK, CURVEs, images, information, NOTEs, or other data that is uploaded, submitted, or input into the Feather by the USER or that forms part of the USER&apos;s INTELLECTUAL PROPERTY. For the avoidance of doubt, USER CONTENT does not include USAGE DATA or any FEATHER DATA.
      </StyledDoubleDigitNum>
      <StyledDoubleDigitNum>
        33.
        <StyledLabel>&nbsp;&nbsp;UPDATE&nbsp;</StyledLabel>
        : means new versions of the FEATHER or SKETCHSOFT TECHNOLOGY which is a successor to or substitute for a qualifying prior release, which may include but is not limited to error connections, bug fixes, patches, service packs provided by SKETCHSOFT from time to time.
      </StyledDoubleDigitNum>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[2] License</StyledH3>
      <StyledH4>1. Grant of License </StyledH4>
      <StyledP>By accepting the terms and condtions of theses TERMS OF SERVICE, the USER is granted a limited, non-transferable, non-saleable, non-exclusive, non-rental, non-sublicensable, worldwide license to access and use FEATHER for the duration of this AGREEMENT in accordance with the terms and conditions of theses TERMS OF SERVICE.</StyledP>
      <StyledH4>2. Access Credential </StyledH4>
      <StyledP>The license of USER shall commence as of the EFFECTIVE DATE. All AUTHORIZED USERS must have valid ACCESS CREDENTIAL.</StyledP>
      <StyledH4>3. Restrictions </StyledH4>
      <StyledP>USER may access or use FEATHER only as expressly permitted in this TERMS OF SERVICE, and USER may not allow any other parties to: (a) decompile, disassemble, or otherwise reverse engineer the SOFTWARE; (b) distribute, sell, sublicense, rent, lease, or otherwise commercial use (except as expressly agreed to by SKETCHSOFT), provide FEATHER to any thrid party, or use the SOFTWARE for any service office or time-sharing purpose; (c) copy (except as expressly permitted in this TERMS OF SERVICE), modify, create, derive, work, or improve of any part of FEATHER, or incorporate the SOFTWARE into or with other software, except to the extent expressly authorized in writing by SKETCHSOFT; (d) access or use FEATHER other than by an AUTHORIZED USER through the his or her own valid ACCESS CREDENTIAL; (e) use or host the SOFTWARE in a virtual server environment; (f) access or use FEATHER for any competitive analysis of software, development, provision, or use of a competing software, service, or product, or any other purpose that gives SKETCHSOFT damage or commercial disadvantage; or (g) remove, circumvent, disable, damage, or otherwise interfere with or disrupt the integrity or performance of the SOFTWARE or any security-related features therein. Any attempt to do so is a violation of the rights of SKETCHSOFT and its licensors. If USER breach this restriction, USER may be subject to prosecution and damages. The terms of the license will govern any upgrades provided by SKETCHSOFT that replace and/or supplement the original SOFTWARE, unless such upgrade is accompanied by a separate license in which case the terms of that license will govern. USER must comply with applicable third party terms of agreement when using the SOFTWARE.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[3] Fees and Payments</StyledH3>
      <StyledP>SKETCHSOFT may provide access to the SKETCHSOFT TECHNOLOGY free of charge or introduce charges or paid subscriptions applicable to the SKETCHSOFT TECHNOLOGY. SKETCHSOFT, at any time, may change the charges or paid subscriptions applicable to the SKETCHSOFT TECHNOLOGY by posting a written notification through the SITE and/or FEATHER and/or FEATHER PLATFORM to USER.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[4] Updates</StyledH3>
      <StyledH4>1. Updates and Maintenance</StyledH4>
      <StyledP>Any updates will install automatically when USER&apos;s device is connected to the Internet and all necessary services are functioning. SKETCHSOFT provides continuous support for FEATHER. SKETCHSOFT has and will retain sole control over th operation, provision, maintenance, and management of FEATHER.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[5] Support</StyledH3>
      <StyledH4>1. Support</StyledH4>
      <StyledP>
        We offer support through our support channels. You can ask us (about our subscriptions, 3d sketch and any relevant topic) using our forum available on our SITE (
        <StyledA href="https://feather.art/">https://feather.art/</StyledA>
        ) or write us at
        <StyledA href="mailto:support@sketchsoft3d.com">&nbsp;support@sketchsoft3d.com</StyledA>
        . We will usually get back to you within 3 BUSINESS DAYs.
      </StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[6] Propritary Rights(IP) & USER Content</StyledH3>
      <StyledH4>1. Propritary Rights</StyledH4>
      <StyledH4>SKETCHSOFT IP </StyledH4>
      <StyledP>SKETCHSOFT owns all rights, title, and interest in and to: (a) logos and trademarks and all product names associated with the SKETCHSOFT TECHNOLOGY which includes FEATHER and/or FEATHER PLATFORM; (b) the SKETCHSOFT TECHNOLOGY and all derivative works thereof; (c) the USAGE DATA; (d) any other documents, user interfaces, technology, know-how, trade secrets, designs, inventions, and other tangible or intangible technical material or information used to provide the SKETCHSOFT TECHNOLOGY, and/or conceived, inferred or developed as part of the SKETCHSOFT TECHNOLOGY; and (e) all IP Rights mentioned above (collectively, the “SKETCHSOFT IP”). Except as expressly provided in this EULA, no license or other rights of the SKETCHSOFT IP are given to USER and all such rights are expressly reserved.</StyledP>
      <StyledH4>USER IP </StyledH4>
      <StyledP>As between USER and SKETCHSOFT, USER owns all right, title, and interest in and to: (a) UESER CONTENT and/or RESULTS; (b) USER’s logos and trademarks and all product names associated with USER; and (c) all IP Rights mentioned above (collectively, the “USER IP”). Except as expressly provided in this TERMS OF SERVICE, no license or other rights of the USER IP are granted to SKETCHSOFT, and all such rights are expressly reserved.</StyledP>
      <StyledH4>FEEDBACK </StyledH4>
      <StyledP>By submitting any FEEDBACK, USER transfers all right, title, and interest in FEEDBACK to SKETCHSOFT. All results and findings related to the performance of the SKETCHSOFT TECHNOLOGY are considered confidential information of SKETCHSOFT, and upon SKETCHSOFT’s request, USER provides SKETCHSOFT with the results of evaluation or use of the SKETCHSOFT TECHNOLOGY.</StyledP>
      <StyledH4>2. USER CONTENT</StyledH4>
      <StyledH4>Use of USER CONTENT</StyledH4>
      <StyledP>SKETCHSOFT is not responsible for any loss, destruction, alteration, uploading or disclosure of USER CONTENT and/or RESULT caused by the USER or any third party. USER&apos;s use of USER CONTENT and/or RESULT is at USER&apos;s responsibility.</StyledP>
      <StyledH4>Warranty of USER CONTENT</StyledH4>
      <StyledP>SKETCHSOFT does not warranty that the USER CONTENT and/or RESULTS are accurate, complete, or useful for any purpose. SKETCHSOFT does not examine whether USER CONTENT and/or RESULT uploaded to the SITE are appropriate or virus-free, and SKETCHSOFT does not determine whether inspection is technically possible.</StyledP>
      <StyledH4>In Case of Breach of the AGREEMENT</StyledH4>
      <StyledP>SKETCHSOFT reserves the right to review any USAGE DATA and/or RESULT to ensure that this AGREEENT is not violated. In case of breach of the AGREEMENT, SKETCHSOFT can remove USER&apos;s access to SKETCHSOFT TECHNOLOGY which includes FEATHER and/or FEATHER PLATFORM and terminate their ACCOUNT. SKETCHSOFT also reserves the right to delete any USAGE DATA and/or RESULT that violate or potentially violate with this AGREEMENT including any applicable laws or conditions of use permitted in any jurisdiction where the SKETCHSOFT TECHNOLOGY is used or may be used.</StyledP>
      <StyledH4>Creative Commons Licences of USER CONTENT and/or RESULT Contributed to the FEATHER PLATFORM</StyledH4>
      <StyledP>
        In case a USER contribute to the FEATHER PLATFORM, USER is acknowledged and agreed that all content including USER CONTENT and/or RESULT published to FEATHER PLATFORM is licensed by the Creative Commons Attribution-NonCommercial-ShareAlike licence (CC BYNC-SA, see&nbsp;
        <StyledA href="https://www.creativecommons.org">https://www.creativecommons.org</StyledA>
        ). The USER agrees that SKETCHSOFT may make the USER CONTENT and/or RESULT published on FEATHER PLATFORM available to others on the condition that others must comply with the terms of the ShareAlike license, and even if not, the ShareAlike license cannot be revoked. However, SKETCHSOFT shall not be responsible for ensuring compliance with the ShareAlike Licence.
      </StyledP>
      <StyledH4>Back-up and Removal of USER CONTENT and RESULT</StyledH4>
      <StyledP>USERs are responsible for maintaining backup copies of all USER CONTENT and/or RESULTs relying on the SKETCHSOFT TECHNOLOGY. USERs may, in their sole discretion, remove certain USER CONTENT and/or RESULT generated or published through FEATHER and/or FEATHER PLATFORM. However, some USER CONTENT and/or RESULT, including posts or comments published and licensed on the FEATHER PLATFORM, may not be removed and copies of USER CONTENT and/or RESULT may continue to exist in archive or backup form. SKETCHSOFT may remove or delete USER CONTENT and/or RESULT within a reasonable period after ACCOUNT termination or cancellation under this AGREEMENT. SKETCHSOFT is not responsible for any removal or failure to remove of any of the USER CONTNET and/or RESULT.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[7] Technology Usage Statistics</StyledH3>
      <StyledH4>1. Access and/or Collection of USAGE DATA, USER CONTENT and/or RESULT</StyledH4>
      <StyledP>USER agrees to the collection of analytical USAGE DATA generated by using SKETCHSOFT TECHNOLOGY. AUTHORIZED USER shall be acknowledged and agree to the collection and access of USER CONTENT and/or RESULT that occurs while using SKETCHSOFT TECHNOLOGY. USAGE DATA, USER CONTENT and/or RESULT collected by SKETCHSOFT is Retained for 5 years from the time of ACCOUNT registration and then destroyed. AUTHORIZED USER shall be acknowledged and agree that all USAGE DATA, USER CONTENT and/or RESULT collected by Sketchsoft is used for research purposes only, and that it is managed by SKETCHSOFT.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[8] Third Party Services</StyledH3>
      <StyledH4>1. TPS Agreement </StyledH4>
      <StyledP>FEATHER may contain or be acoompanied by TPS. A USER may be able access FEATHER or use integrated features by connecting their ACCOUNT with certain TPS.</StyledP>
      <StyledH4>2. TPS Dependencies </StyledH4>
      <StyledP>FEATHER has third party dependencies which may affect its availability, including (without limitation) internet service providers and hosting services, and that the SKETCHSOFT has no means to control the availability of these dependencies.</StyledP>
      <StyledH4>3. TPS Guarantee </StyledH4>
      <StyledP>SKETCHSOFT is not affiliated with TPS and cannot guarantee the effectiveness of any TPS connection. SKETCHSOFT shall not be responsible or liable in any way for: (a) interruptions of FEATHER availability due to TPS; or (b) information contained in the linked TPS.</StyledP>
      <StyledH4>4. TPS Responsibility </StyledH4>
      <StyledP>User acknowledges and agrees that SKETCHSOFT is not responsible for, and makes no representations or warranties for such TPS or USER&apos;s use of such TPS. To the fullest extent permitted by law, SKETCHSOFT expressly disclaims any express or implied terms with regard to TPS.</StyledP>
      <StyledH4>5. TPS License </StyledH4>
      <StyledP>USER will take sole responsibility for obtaining and complying with any licences that USER may use or may be required to use the TPS USER have acquired for use with SEKTCHSOFT TECHNOLOGY.</StyledP>
      <StyledH4>6. Data </StyledH4>
      <StyledP>Where a USER connects and/or registers their ACCOUNT using a TPS, the USER authorizes that TPS to create a USER&apos;s profile within FEATHER PLATFROM using data from that TPS.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[9] Confidential Information</StyledH3>
      <StyledH4>1. Security </StyledH4>
      <StyledP>The SKETCHSOFT takes the security of FEATHER and the privacy of its USERs very seriously. The USER agrees not to take any action to infringe the security or priacy of SKETCHSOFT TECHNOLOGY or its information.</StyledP>
      <StyledH4>2. Confidential Information </StyledH4>
      <StyledP>Confidential Information in this AGREEMENT means information that is clearly labelled or identified as confidential, or ought reasonably by treated as being confidential, and includes DOCUMENTATION, FEATHER DATA, SEKTCHSOFT TECHNOLOGY, SOFTWARE, USAGE DATA, and USER CONTENT. Confidential Information excludes any information which: (a) is or becomes publicly known except in violation of this TERMS OF SERVICE; (b) was lawfully in the possession of the receiving party prior to disclosure; (c) is lawfully disclosed to the receiving party by a third party without restriction on disclosure; (d) is independently developed by the receiving party and can substantiated by written evidence; or (e) is required to be disclosed by law, by any court of competent jurisdiction, or by any regulatory or administrative body.</StyledP>
      <StyledH4>3. Duty </StyledH4>
      <StyledP>Each party will hold the other&apos;s Confidential Information in confidence and will not make the other&apos;s Confidential Information available to any third party unless equivalent confidentality obligation applies to the third party. Neither party will use the other&apos;s Confidential Information for any purpose other than the implementation of this TERMS OF SERVICE. Each party will take all reasonable steps to ensure that the other&apos;s Confidential Information to which it has access is not disclosed or distributed by its Personnel in breach of the TERMS OF SERVICE.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[10] Desclaimer / Waiver</StyledH3>
      <StyledP>1. Desclaimer and Waiver</StyledP>
      <StyledP>By using the FEATHER and/or FEATHER PLATFORM Beta Services, USER must acknowledge and agree that the SOFTWARE may not yet be fully functional. USERs acknowledge that while using the FEATHER and/or FEATHER beta services, bugs and errors may occur, and problems that arise are not warranted by SKETCHSOFT. Depending on the occurrence of bugs and errors, SKETCHSOFT TECHNOLOGY instability and access problems may occur during the process of using the SKETCHSSOFT TECHNOLOGY, and USER CONTENT and/or RESULT may be lost. The UPDATE process is automatic, USER is aware that USER CONTENT and/or RESULT created by the USER may change during this process, and SKETCHSOFT does not guarantee any problems caused by this.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[11] Liability & Indemnity</StyledH3>
      <StyledH4>1. Liability</StyledH4>
      <StyledP>Nothing in this AGREEMENT limits or excludes the liability of either party for death or personal injury caused by its negligence, fraud or fraudulent misrepresentation, and/or any other act, omission, or liability which may not be limited or excluded by applicable law. SKETCHSOFT shall not be liable for any loss of use, lost DATA, interruption of business, or any indirect, special, incidental, or consequential damages of any kind (including lost profits), regardless of the form of action, whether in contract, tort (including negligence), strict liability, or otherwise, even if informed of the possibility of such of such damages in advance.</StyledP>
      <StyledH4>2. Limitation of Liability</StyledH4>
      <StyledP>Notwithstanding any other provision in this AGREEMENT, SKETCHSOFT&apos;s entire liability to. SER arising from this AGREEMENT shall not exceed the amount actually paid by USER to SKETCHSOSFT under this AGREEMENT in twelve(12) month period immediately preceding the occurrence of the event giving rise to the claim.</StyledP>
      <StyledP>(UNDER NO CIRCUMSTANCES EXCEED £100 (GREAT BRITISH POUNDS) OR THE FEES PAID BY CUSTOMER IN THE LAST 12 MONTHS PRIOR TO THE DATE THE LIABILITY AROSE, WHICHEVER IS GREATER.)</StyledP>
      <StyledH4>3. Indemnification by USER</StyledH4>
      <StyledP>USER agrees to defend, hold harmless, and/or indemnify SKETCHSOFT (and its officers, directors, employees, agents and service providers) from and against any damages arising from or in any way related to any claim that: (a) any violation of these AGREEMENT (including negligent or wrongful conduct) by USER, (b) relates to USER&apos;s access or use of SKETCHSOFT TECHNOLOGY, (c) information, USER CONTENT and/or RESULT provided by USER, or (d) SKETCHSOFT&apos;s use of USER CONTENT and/or RESULT in accordance with this AGREEMENT, including without limitation, any assertion that USER CONTENT and/or RESULT or the use thereof may infringe or misappropriate any IP Rights or other rights of any individual or entity, or contain any libelous, defamatory, disparaging, pornographic, or obscene materials. USER agrees to fully cooperate at USER&apos;s expense, reasonably required by SKETCHSOFT. SKETCHSOFT may assume the defense and control of any matter for which SKETCHSOFT is indemnified hereunder. USER shall not settle any matter involving SKETCHSOFT without SKETCHSOFT consent. If SKETCHSOFT requests that USER defend a claim, the customer will not consent to the settlement without SKETCHSOFT&apos;s prior written consent. SKETCHSOFT reserves the right to participate in the defence of the claim with legal counsel of its choice including in-house attorneys at its own expense.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[12] Termination</StyledH3>
      <StyledH4>1. Termination </StyledH4>
      <StyledP>This TERMS OF SERVICE is effective on the EFFECTIVE DATE and expires at the end of the license term or before that date.The license term will automatically renew for a subsequent 30 days (1 period) unless the party informs the other party that it does not wish to renew at least 30 days before the renewal date.</StyledP>
      <StyledH4>2. Delete Account </StyledH4>
      <StyledP>The USER may delete an ACCOUNT at any time by written notice to SKETCHSOFT or in any other manner as SKETCHSOFT may direct from time to time. When the USER delete an ACCOUNT, SKETCHSOFT retains the USER&apos;s information (including Confidential Information) for 30 days and then deletes it.</StyledP>
      <StyledH4>3. Termination by SKETCHSOFT </StyledH4>
      <StyledP>SKETCHSOFT may terminate this AGREEMENT or delete an ACCOUNT with immediate effect by written notice to USER only if USER: (a) in violation of any terms of this TERMS OF SERVICE; (b) infringe any rights in SKETCHSOFT&apos;s or TPS&apos;s IP rights; or (c) failure to pay the license fee as determined in accordance with this TERMS OF SERVICE. For clarity, if a USER stops paying for SUBSCRIPTION FEE but does not delete an ACCOUNT, they will be downgraded to a free ACCOUNT with limited access at the end of the USER&apos;s active subscription period.</StyledP>
      <StyledH4>4. Effects of Termination </StyledH4>
      <StyledP>(1) If ACCOUNT is terminated or deleted, these TERMS OF SERVICE and any license granted to USER under this TERMS OF SERVICE will terminate immediately. (2) Termination of this AGREEMENT shall not affect any rights or remedies accrued by any party arising out of this AGREEMENT until the expiration or termination date. (3) User must stop using all SKETCHSOFT TECHNOLOGY and FEATHER on termination of this AGREEMENT. SKETCHSOFT may disable all access to the SOFTWARE and FEATHER.</StyledP>
      <StyledH4>5. Survival </StyledH4>
      <StyledP>All provisions of this AGREEMENT, express or implied, which are terminated or intended to survive termination, shall remain in full force and effect, including but not limited to: Section 1 (Introduction).</StyledP>
    </Wrapper>
  );
}

export default TermsContents;

const Wrapper = styled.section`
`;

const StyledH3 = styled.h3`
  margin-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.875rem;

  letter-spacing: -0.02em;

  color: #21232B;
`;

const StyledH4 = styled.h4`
  margin-bottom: 1.5rem;
  font-weight: 600;
  font-size: 1.25rem;
`;

const StyledDoubleDigitNum = styled.p`
  margin-left: 1.25em;
  margin-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 140%;

  letter-spacing: -0.02em;
  text-indent: -1.5em;

  color: #21232B;
`;

const StyledNum = styled.p`
  margin-left: 1.25em;
  margin-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 140%;

  letter-spacing: -0.02em;
  text-indent: -1em;

  color: #21232B;
`;

const StyledP = styled.p`
  margin-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 140%;

  letter-spacing: -0.02em;

  color: #21232B;
`;

const StyledA = styled.a`
  margin-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 140%;
  text-decoration: none;
  border-bottom: 1px solid black;

  letter-spacing: -0.02em;

  color: #21232B;
`;

const StyledLabel = styled.span`
  font-weight: 600;
`;
