import React from 'react';
import { Routes, Route, Navigate } from 'react-router';
import { Helmet } from 'react-helmet';

import useSwitchThemeWithUrl from '../hooks/useSwitchThemeWithUrl';
import useInitializeScroll from '../hooks/useInitializeScroll';

import Layout from '../layout';
import Product from './Product';
import Explore from './Explore';
import Pricing from './Pricing';
import Download from './Download';
import Terms from './Terms';
import Privacy from './Privacy';

function Pages() {
  useSwitchThemeWithUrl({
    // TODO REMOVE '/PrivacyPolicy' AFTER RELEASED NEW APP VERSION
    light: ['/', '/pricing', '/download', '/PrivacyPolicy', '/privacy', '/terms'],
    dark: ['/explore'],
  });

  useInitializeScroll();

  return (
    <>
      <Helmet>
        <title>Feather: 3D Sketchbook</title>
        <meta
          name="description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta name="author" content="Sketchsoft Inc." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://feather.art" />
        <meta property="og:title" content="Feather: 3D Sketchbook" />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/feather-image.jpg`} />
        <meta
          property="og:description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta property="og:site_name" content="Feather" />
      </Helmet>
      <Layout>
        <Routes>
          <Route path="/" element={<Product />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/download" element={<Download />} />
          <Route path="/terms" element={<Terms />} />
          {/** TODO REMOVE '/PrivacyPolicy' ROUTE AFTER RELEASED NEW APP VERSION */}
          <Route path="/PrivacyPolicy" element={<Navigate to="/privacy" />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </>
  );
}

export default Pages;
