const load = (() => {
  let promise: Promise<void> | undefined;

  return async (id: string) => {
    if (promise) return promise;

    promise = new Promise(res => {
      const script = document.createElement('script');
      script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${id}');
        fbq('track', 'PageView');
      `;

      const img = document.createElement('img');
      img.height = 1;
      img.width = 1;
      img.style.display = 'none';
      img.src = `https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`;

      const noscript = document.createElement('noscript');
      noscript.appendChild(img);

      document.head.append(script, noscript);

      res();
    });

    return promise;
  };
})();

const metapixel = { load };

export default metapixel;
