import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { ReactComponent as Border } from '../../assets/svgs/button-border.svg';

interface ButtonProps {
  className?: string;
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
}

function Button({ className, children, onClick, disabled, type = 'button' }: ButtonProps) {
  return (
    <Wrapper className={className} type={type} onClick={onClick} disabled={disabled}>
      {children}
      <StyledBorder />
    </Wrapper>
  );
}

export default Button;

const Wrapper = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
`;

const StyledBorder = styled(Border)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
