import React from 'react';
import styled from 'styled-components';

import Heading2 from '../common/Heading2';
import Button from '../common/Button';

export interface DownloadFeatherProps {
  className?: string;
  onDownload: () => void;
}

function DownloadFeather({ className, onDownload }: DownloadFeatherProps) {
  return (
    <Wrapper className={className}>
      <Heading2>{'More ideas.\nFrom you.\nWith Feather.'}</Heading2>
      <Button onClick={onDownload}>Download free</Button>
    </Wrapper>
  );
}

export default DownloadFeather;

const Wrapper = styled.section`
  text-align: center;

  & > ${Heading2} {
    line-height: 110%;
    white-space: pre;
    margin-bottom: 2rem;
  }

  & > button {
    width: 164px;
    height: 54px;
    margin: auto;
    font-size: 1rem;
  }
`;
