const BLACK = '#0E0E0E';

const WHITE = '#FFFFFF';

const SEMI_BLACK = '#21232B';

const SEMI_WHITE = '#FBFBFF';

export type ThemeType = 'light' | 'dark'

export type ColorKey = 'background' | 'color' | 'fill';

export type Theme = Record<ColorKey, string>

export type Themes = Record<ThemeType, Theme>

const LIGHT: Theme = {
  background: SEMI_WHITE,
  color: SEMI_BLACK,
  fill: SEMI_BLACK,
} as const;

const DARK: Theme = {
  background: BLACK,
  color: WHITE,
  fill: WHITE,
} as const;

const THEMES: Themes = {
  light: LIGHT,
  dark: DARK,
} as const;

export default THEMES;
