import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_MOBILE_VERTICAL_MAX,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../constants/media';

import Heading2 from '../components/common/Heading2';
import PrivacyContents from '../components/policy/PrivacyContents';

function Privacy() {
  return (
    <>
      <Helmet>
        <title>Feather - Privacy policy</title>
        <meta
          name="description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta name="author" content="Sketchsoft Inc." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://feather.art/privacy" />
        <meta property="og:title" content="Feather - Privacy policy" />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/feather-image.jpg`} />
        <meta
          property="og:description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta property="og:site_name" content="Feather" />
      </Helmet>
      <Wrapper>
        <StyledHeading2>
          PRIVACY and
          {' '}
          <span>COOKIE POLICY</span>
        </StyledHeading2>
        <StyledLastUpdate>
          <StyledLabel>Last Update</StyledLabel>
        &nbsp;: August 25, 2021
        </StyledLastUpdate>
        <PrivacyContents />
      </Wrapper>
    </>
  );
}

export default Privacy;

const Wrapper = styled.div`
  padding: 0 5rem;
  margin-bottom: 25rem;

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    padding: 0 2.75rem;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    padding: 0 2rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    padding: 0;
  }
`;

const StyledHeading2 = styled(Heading2)`
  margin: 8rem 0 1.25rem 0;
  line-height: 120%;
  & > span {
    display: inline-block;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    font-size: 2.5rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    max-width: 350px;
  }
`;

const StyledLastUpdate = styled.div`
  font-size: 1.25rem;
  margin-bottom: 5rem;
`;

const StyledLabel = styled.div`
  display: inline-block;
  font-weight: 700;
`;
