import React, { useState, createContext, useMemo } from 'react';
import { createGlobalStyle, ThemeProvider, ThemeConsumer } from 'styled-components';

import { MEDIA_MAX_MOBILE_VERTICAL_MAX } from '../constants/media';
import themes, { ThemeType } from '../constants/theme';
import resetStyle from '../constants/resetStyle';

interface ProviderProps {
  type?: ThemeType,
  children: React.ReactNode,
}

const GlobalThemedStyle = createGlobalStyle`
  ${resetStyle}

  body {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }

  svg {
    fill: ${({ theme }) => theme.fill};

    & path[class='background'] {
      fill: ${({ theme }) => theme.background};
    }
  }

  button {
    color: ${({ theme }) => theme.background};
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    html {
      font-size: 14px;
    }
  }
`;

const ToggleContext = createContext<{ type: ThemeType, toggle:(type: ThemeType) => void, }>({
  type: 'light',
  toggle: () => {},
});

function CustomedThemeProvider({ type = 'light', children }: ProviderProps) {
  const [themeType, setType] = useState<ThemeType>(type);

  const [targetTheme, toggleValue] = useMemo(() => [
    themes[themeType],
    { type: themeType, toggle: setType },
  ], [themeType]);

  return (
    <ToggleContext.Provider value={toggleValue}>
      <ThemeProvider theme={targetTheme}>
        <GlobalThemedStyle />
        {children}
      </ThemeProvider>
    </ToggleContext.Provider>
  );
}

const ThemeColorContext = {
  Provider: ThemeProvider,
  Consumer: ThemeConsumer,
};

export {
  CustomedThemeProvider as ThemeProvider,
  ThemeColorContext,
  ToggleContext as ThemeTypeContext,
};
