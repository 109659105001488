import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { MEDIA_MAX_MOBILE_HORIZONTAL_MAX } from '../constants/media';

import Logo from '../components/common/Logo';
import Button from '../components/common/Button';

import VerticalMenu from './VerticalMenu';

import { ReactComponent as MenuSvg } from '../assets/svgs/system-menu.svg';

function Header() {
  const [isOpen, setOpen] = useState(false);

  const navigate = useNavigate();

  const toggle = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  const handleDownloadClick = useCallback(() => {
    setOpen(false);
    navigate('/download');
  }, [navigate]);

  return (
    <StyledHeader>
      <Link to="/">
        <Logo />
      </Link>
      <LinkRowList>
        <Link to="/">Product</Link>
        <Link to="/explore">Explore</Link>
        <a target="_blank" href={process.env.REACT_APP_SUPPORT_URL} rel="noreferrer">Support</a>
        <Link to="/pricing">Pricing</Link>
        <StyledButton onClick={handleDownloadClick}>Download free</StyledButton>
      </LinkRowList>
      <SystemMenu onClick={toggle} />
      {isOpen && (
        <VerticalMenu onClose={toggle}>
          <LinkColumnList>
            <Link to="/" onClick={toggle}>Product</Link>
            <Link to="/explore" onClick={toggle}>Explore</Link>
            <a target="_blank" href={process.env.REACT_APP_SUPPORT_URL} rel="noreferrer">Support</a>
            <Link to="/pricing" onClick={toggle}>Pricing</Link>
            <StyledButton onClick={handleDownloadClick}>Download free</StyledButton>
          </LinkColumnList>
        </VerticalMenu>
      )}
    </StyledHeader>
  );
}

export default Header;

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;

const SystemMenu = styled(MenuSvg)`
  cursor: pointer;
  display: none;
  width: 24px;
  height: 24px;
  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  font-size: 0.875rem;
  width: 140px;
`;

const LinkRowList = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;

  & > * {
    display: inline-block;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    display: none;
  }
`;

const LinkColumnList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
`;
