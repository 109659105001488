import styled from 'styled-components';
import {
  MEDIA_MAX_MOBILE_VERTICAL_MAX,
  MEDIA_MAX_TABLET_HORIZONTAL_WIDTH,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';

const Type = styled.p`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140%;
  letter-spacing: -0.02em;

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    font-size: 1.375rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 1rem;
  }
`;

const Price = styled.h3`
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 140%;
  letter-spacing: -0.02em;

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    font-size: 2.75rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 1.7rem;
  }
`;

const Status = styled.p`
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 140%;
  color: #7B7B8B;
  letter-spacing: -0.02em;

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    font-size: 1.375rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 1rem;
  }
`;

const Subtitle = styled.h5`
  font-weight: 600;
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: -0.02em;

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    font-size: 1.625rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 1.3rem;
  }
`;

const Target = styled(Subtitle)`
`;

const Description = styled.p`
  font-size: 0.875rem;
  line-height: 140%;
  letter-spacing: -0.02em;

  ${MEDIA_MAX_TABLET_HORIZONTAL_WIDTH} {
    font-size: 0.75rem;
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    font-size: 1.375rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 1rem;
  }
`;

const CardTexts = {
  Type,
  Price,
  Status,
  Subtitle,
  Target,
  Description,
};

export default CardTexts;
