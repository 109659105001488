import styled from 'styled-components';

import { ReactComponent as Divider } from '../../assets/svgs/row-solid-divider.svg';

const RowSolidDivider = styled(Divider)`
  fill: none;
  stroke: ${({ theme }) => theme.color};
  width: 100%;
`;

export default RowSolidDivider;
