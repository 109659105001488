import React from 'react';
import styled from 'styled-components';

import Logo from '../components/common/Logo';
import { ReactComponent as Cross } from '../assets/svgs/cross.svg';
import RowSolidDivider from '../components/common/RowSolidDivider';

export interface VerticalMenuProps {
  className?: string;
  children?: React.ReactNode;
  onClose: () => void,
}

function VerticalMenu({ className, children, onClose }: VerticalMenuProps) {
  return (
    <Wrapper className={className}>
      <Icons>
        <Logo />
        <Cross onClick={onClose} />
      </Icons>
      <RowSolidDivider />
      <div>
        {children}
      </div>
    </Wrapper>
  );
}

const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
  & > svg:last-child {
    width: 24px;
    height: 24px;
    fill: none;
    stroke: ${({ theme }) => theme.color};
  }
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.background};

  & > div:last-child {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default VerticalMenu;
