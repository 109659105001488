const gtagURL = 'https://www.googletagmanager.com/gtag/js';
const gtagScriptId = 'g_tag_script_url';

const load = (() => {
  let promise: Promise<void> | undefined;

  return async (id: string) => {
    if (promise) return promise;

    promise = new Promise((res, rej) => {
      const script = document.createElement('script');
      script.id = gtagScriptId;
      script.onload = () => res();
      script.onerror = () => {
        document.head.removeChild(script);
        rej();
      };
      script.src = `${gtagURL}?id=${id}`;
      document.head.appendChild(script);
    });

    return promise;
  };
})();

const gtag = (() => {
  window.dataLayer = window.dataLayer || [];

  const func: Gtag.Gtag = function func() {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };

  return func;
})();

async function init(id: string) {
  await load(id);
  gtag('js', new Date());
  gtag('config', id);
}

const GA = { load, init, gtag };

export default GA;
