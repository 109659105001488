import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as Discord } from '../assets/svgs/discord-icon.svg';
import { ReactComponent as Instagram } from '../assets/svgs/instagram-icon.svg';
import { ReactComponent as Sketchfab } from '../assets/svgs/sketchfab-icon.svg';
import { ReactComponent as Twitter } from '../assets/svgs/twitter-icon.svg';
import { ReactComponent as Youtube } from '../assets/svgs/youtube-icon.svg';
import { MEDIA_MAX_MOBILE_HORIZONTAL_MAX } from '../constants/media';

function Footer() {
  return (
    <Wrapper>
      <Copyright>© 2022 Sketchsoft Inc. All rights reserved.</Copyright>
      <Icons>
        <a target="_blank" href={process.env.REACT_APP_INSTAGRAM_URL} rel="noreferrer" aria-label="Instagram">
          <Instagram />
        </a>
        <a target="_blank" href={process.env.REACT_APP_TWITTER_URL} rel="noreferrer" aria-label="Twitter">
          <Twitter />
        </a>
        <a target="_blank" href={process.env.REACT_APP_YOUTUBE_URL} rel="noreferrer" aria-label="Youtube">
          <Youtube />
        </a>
        <a target="_blank" href={process.env.REACT_APP_SKETCHFAB_URL} rel="noreferrer" aria-label="Sketchfab">
          <Sketchfab />
        </a>
        <a target="_blank" href={process.env.REACT_APP_DISCORD_URL} rel="noreferrer" aria-label="Discord">
          <Discord />
        </a>
      </Icons>
      <Links>
        <a target="_blank" href={process.env.REACT_APP_COMPANY_HOMEPAGE_URL ?? ''} rel="noreferrer">Company</a>
        <Link to="/terms">Terms</Link>
        <Link to="/privacy">Privacy</Link>
      </Links>
    </Wrapper>
  );
}

export default Footer;

const Copyright = styled.span`
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & > svg {
    cursor: pointer;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 1.75rem;
`;

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  padding: 24px 0;
  font-size: 0.75rem;

  & > ${Copyright} {
    margin-right: 3rem;
  }

  & > ${Icons} {
    margin-right: auto;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    flex-direction: column;
    padding: 50px 0;

    & > ${Copyright} {
      order: 3;
      margin-right: 0;
    }

    & > ${Icons} {
      order: 1;
      margin-right: 0;
    }

    & > ${Links} {
      order: 2;
      margin: 34px 0;
    }
  }
`;
