import React from 'react';
import styled from 'styled-components';

import IPadAppStore, { IPadAppStoreProps } from './IPadAppStore';
import GalaxyChrome, { GalaxyChromeProps } from './GalaxyChrome';
import { MEDIA_MAX_MOBILE_HORIZONTAL_MAX, MEDIA_MAX_MOBILE_VERTICAL_MAX } from '../../constants/media';

export interface DownloadByDeviceProps {
  className?: string;
  onAppStore: IPadAppStoreProps['onAppStore'];
  onWebAppInstall: GalaxyChromeProps['onWebAppInstall'];
}

function DownloadByDevice({ className, onAppStore, onWebAppInstall }: DownloadByDeviceProps) {
  return (
    <Wrapper className={className}>
      <IPadAppStore onAppStore={onAppStore} />
      <GalaxyChrome onWebAppInstall={onWebAppInstall} />
    </Wrapper>
  );
}

export default DownloadByDevice;

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  & > *:last-child {
    margin-top: 2%;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    flex-direction: column;
    & > * {
      width: 80%;

      & button {
        width: 220px;
        height: 50px;
      }
    }
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    & > * {

      & button {
        width: 180px;
        height: 40px;
      }
    }
  }
`;
