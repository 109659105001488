import React, { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

export interface ModalProps {
  open: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

function stopPropagation(e: React.UIEvent) {
  e.preventDefault();
  e.stopPropagation();
}

function Modal({ open, children, onClose }: ModalProps) {
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = useCallback((e: React.MouseEvent) => {
    if (ref.current === e.target) {
      onClose?.();
    }
  }, [onClose]);

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'initial';
    }

    return () => {
      document.body.style.overflowY = 'initial';
    };
  }, [open]);

  if (!open) return null;
  return createPortal(
    <Overlay ref={ref} onClick={handleClose} onTouchMove={stopPropagation} onWheel={stopPropagation}>
      {children}
    </Overlay>,
    document.body,
  );
}

export default Modal;

const Overlay = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
`;
