import React from 'react';
import styled from 'styled-components';

export interface ToolGuideProps {
  className?: string;
  icon: React.ReactElement;
  guide: string;
}

function ToolGuide({ className, icon, guide }: ToolGuideProps) {
  return (
    <Wrapper className={className}>
      <Icon>
        {icon}
      </Icon>
      <p>{guide}</p>
    </Wrapper>
  );
}

export default ToolGuide;

const Icon = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  margin-right: 0.75rem;
  border: 1px solid ${({ theme }) => theme.color};
  border-radius: 8px;

  & > svg {
    width: 25px;
    height: 25px;
    fill: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > p {
    font-size: 0.875rem;
    line-height: 140%;
  }
`;
