import React from 'react';
import styled from 'styled-components';
import Heading2 from '../common/Heading2';

import IPadVideo, { IPadVideoProps } from './IPadVideo';

import Button from '../common/Button';
import { MEDIA_MAX_MOBILE_VERTICAL_MAX } from '../../constants/media';

export interface IntroVideoProps {
  className?: string;
  vimeoUrl: IPadVideoProps['vimeoUrl'];
  fullVideoUrl?: IPadVideoProps['fullVideoUrl']
  onDownload: () => void;
}

function IntroVideo({ className, vimeoUrl, onDownload, fullVideoUrl }: IntroVideoProps) {
  return (
    <Wrapper className={className}>
      <StyledIPadVideo vimeoUrl={vimeoUrl} fullVideoUrl={fullVideoUrl} />
      <Heading2>3D sketchbook. Light as a feather.</Heading2>
      <Button onClick={onDownload}>
        Download free
      </Button>
      <Description>
        Available for
        {' '}
        <b>iPadOS</b>
        {' '}
        and
        {' '}
        <b>web.</b>
      </Description>
      <Description>Pen required for drawing.</Description>
    </Wrapper>
  );
}

export default IntroVideo;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > ${Heading2} {
    max-width: 470px;
    margin: 1.25rem auto 2.1875rem;
    text-align: center;
  }

  & > button {
    width: 165px;
    height: 54px;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    & > ${Heading2} {
      max-width: 300px;
    }
  }
`;

const StyledIPadVideo = styled(IPadVideo)`
  width: 100%;
  max-width: 700px;
`;

const Description = styled.p`
  color: #99999C;
  text-align: center;
  line-height: 120%;
  white-space: pre;
`;
