/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

export interface SeeMoreProps {
  className?: string;
  onSeeMore: () => void;
}

function PrivacyContents() {
  return (
    <Wrapper>
      <StyledP>
        This privacy Policy describes how SKETCHSOFT(&quot;we&quot;, &quot;our&quot; or &quot;us&quot;) handles personal information obtained in connection with the operation of the FEATHER WEBSITE&nbsp;
        <StyledA href={process.env.REACT_APP_COMPANY_HOMEPAGE_URL}>feather.art</StyledA>
        &nbsp;(&quot;SITE&quot;), and the associated FEATHER (&quot;SOFTWARE&quot;).
      </StyledP>
      <StyledP>This Privacy Notice applies only to personal information that SKETCHSOFT obtains in connection with the operation of the SITE and SOFTWARE and describes how we can use such information and with whom we may share it.</StyledP>
      <StyledP>By using the SITE or SOFTWARE, you consent to the data practices described in this Privacy Policy. If you do not agree with any part of this Privacy Policy, then we cannot make our Platform available to you and you should stop accessing and using it.</StyledP>
      <StyledP>SKETCHSOFT encourages you to review the privacy statements of websites you choose to navigate to from SITE so that you can understand how those websites collect, use and share your information. SKETCHSOFT is not responsible for the privacy statements or other content on websites outside of the SITE.</StyledP>
      <StyledP>For the purposes of this Privacy Policy, the term &quot;personal data&quot; means any information which identifies you or which allows you to be identified when combined with other information. It does not include data where the identity has been removed (anonymous data).</StyledP>
      <StyledP>The processor of your personal data is SKETCHSOFT, unless indicated otherwise in the present policy.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[1] Purpose of Privacy Policy</StyledH3>
      <StyledP>This Privacy Policy was made to inform our clients of the details of processing their personal data.</StyledP>
      <StyledP>SKETCHSOFT has adopted this Privacy Policy to ensure that we have standards in place to protect the Personal Information that we collect about individuals that is necessary and incidental to operate our SITE(s) and SOFTWARE, deliver the SOFTWARE you have requested, providing the system and services that we offer, and normal day-to-day operations of our business.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[2] Collected information</StyledH3>
      <StyledP>In the course of business it is necessary for us to collect Personal Information. This information allows us to identify who an individual is for the purposes of our business, share Personal Information when asked of us, contact the individual in the ordinary course of business and transact with the individual. Without limitation, we have grouped such information together as follows that we may collect, use, store and transfer.</StyledP>
      <StyledP>We may collect other Personal Information about an individual, which we will maintain in accordance with this Privacy Policy.</StyledP>
      <StyledP>We may also collect non-Personal Information about an individual such as information regarding their computer, network and browser.</StyledP>
      <StyledP>We do not knowingly collect any Special Categories of Personal Data about you (such as details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data) or information about criminal convictions and offences</StyledP>
      <StyledP>We may collect and process data about others that you provide us with, including (but not limited to) information that you provide by filling in forms on the PLATFORM. You represent and warrant to us that you have obtained the express consent from the individuals whose data you provide us with. For the avoidance of any doubt, any reference in this privacy policy to your data shall include data about other individuals that you have provided us with.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[3] Collecting method</StyledH3>
      <StyledP>As there are many circumstances in which we may collect information both electronically and physically, we will endeavour to ensure that an individual is always aware of when their Personal Information is being collected.</StyledP>
      <StyledP>Most information will be collected in association with an individual’s use of SITE and/or SOFTWARE, an enquiry about SITE and/or SOFTWARE or generally dealing with us. However we may also receive Personal Information from sources such as advertising, an individual’s own promotions, public records, mailing lists, contractors, staff, recruitment agencies and our business partners.</StyledP>
      <StyledP>We use different methods to collect Information and/or Data from and about you including through methods listed by the following.</StyledP>
      <StyledH4> 1. Direct interactions.</StyledH4>
      <StyledP>You may give us your name, email address and contact details in person, or by filling in forms on our WEBSITE or SOFTWARE or by corresponding with us by post, phone, email, social media or otherwise. This includes personal data you provide when you access to our PLATFORM; request or receive technical support; meet with one of our employees or consultants; participate in an event which we have organized; complete a form on our website requesting us to contact you; subscribe to our publications or newsletter; request marketing to be sent to you; enter a competition, promotion or survey; submit an application for a role within SKETHCOSFT; and/ or make a complaint or give us some feedback.</StyledP>
      <StyledH4> 2. Automated technologies or interactions.</StyledH4>
      <StyledP>We may automatically obtain certain information, DATA, and/or USAGE DATA when you access the SITE, PLATFORM, and/or SOFTWARE (including receiving newsletter or email).</StyledP>
      <StyledP>We collect Personal Information and distinguish you from others by using cookies and other similar technologies. We may also receive Information and/or Data about you if you visit other websites employing our cookies. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. This helps us to provide you with a good experience when you browse our SITE and/or SOFTWARE and allows us to improve our SITE and/or SOFTWARE. </StyledP>
      <StyledH4> 3. Third parties or publicly available sources.</StyledH4>
      <StyledP>We may receive personal data about you from various third parties as : Technical Data or Usage Data from analytics providers or search information providers; Employment, Identity and Contact Data from third party recruitment agencies.</StyledP>
      <StyledP>In addition, we and third parties may automatically collect certain information through automated means about your activities over time across the browsers, devices you use to access the SITE, PLATFORM, and/or SOFTWARE as well as across third-party sites, apps or other media. The tools that collect this data include technologies such as cookies, web beacons, tags, scripts, pixels, software development kits (collectively, &quot;Cookies&quot;).  These Cookies may be included in our SITE, SOFTWARE, emails, and other digital content. The tools may assign or collect unique cookie IDs or other identifiers associated with your browser or device. These Cookies may assign or collect a unique identifier on your browser or device, and may reside, among other places, on your computer or device, within webpages or mobile apps, such as IP Address for license authentication purposes. We may use these Cookies to administer the SITE and/or SOFTWARE, understand usage of the SITE and/or SOFTWARE and improve the content and offerings of the SITE and/or SOFTWARE. For more information about the use of automated means in the context of interest-based advertising and how you can limit tracking, see the section [7] Opt In and/or Out below.</StyledP>
      <StyledP>If you fail to provide personal information.</StyledP>
      <StyledP>If we are required to collect Personal Information by law or in accordance with the terms of our contract with you, but you do not provide that information upon request, we may not be able to perform or enter into any contract with you (for example, providing you with our software). In this case, we may have to cancel the contract you have with us. But we will notify you if this this happens.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[4] Use of Collected Information</StyledH3>
      <StyledP>In general, the primary principle is that we will not use any Personal Information other than the purpose for which it was collected other than with the individual’s permission.</StyledP>
      <StyledP>We may use the collected information for SITE and/or SOFTWARE for number of purposes, including, but not limited to the following.</StyledP>
      <StyledNum>1. Provide and authenticate license you with the SITE and/or SOFTWARE, including information you may request.</StyledNum>
      <StyledNum>2. Communicate with you by email and other means, including by delivering marketing and promotional communications and materials.</StyledNum>
      <StyledNum>3. Generate and analyze statistics about your use of the SITE and/or SOFTWARE.</StyledNum>
      <StyledNum>4. To perform our contractual obligation to licensed USERS.</StyledNum>
      <StyledNum>5. Use for performance reporting, usage monitoring, trouble shooting about the SITE and/or SOFTWARE.</StyledNum>
      <StyledNum>6. Improve the SITE and/or SOFTWARE, and customize the user experience.</StyledNum>
      <StyledNum>7. Detect, prevent, and respond to violations of this PRIVACY POLICY and/or the TERMS OF USE, violations of law, or other misuse of the SITE and/or SOFTWARE.</StyledNum>
      <StyledP>Additionally the collected Information is used to enable us to operate our business, especially as it relates to an individual including, but not limited to the following.</StyledP>
      <StyledNum>1. The provision of goods and services between an individual and us.</StyledNum>
      <StyledNum>2. Verifying an individual’s identity.</StyledNum>
      <StyledNum>3. Communicating about their relationship with us, our goods and services, our own marketing and promotions to customers and prospects, offers from our partners to our customers, competitions, surveys and questionnaires.</StyledNum>
      <StyledNum>4. Investigating any complaints about or made by an individual.</StyledNum>
      <StyledNum>5. Comply with or enforce applicable law or legal process, including responding to court orders, warrants, summons and other requests from public and government authorities.</StyledNum>
      <StyledP>We use the following service providers who help us with analysing data relating to the operation and utilization of the SITE and/or SOFTWARE</StyledP>
      <StyledList>Google Analytics</StyledList>
      <StyledList>YAMM</StyledList>
      <StyledList>ChannelTalk</StyledList>
      <StyledList>Typeform</StyledList>
      <StyledList>oopy</StyledList>
      <StyledList>Stibee</StyledList>
      <StyledP>We will not disclose or sell an individual’s Personal Information to unrelated third parties under any circumstances, unless the prior written consent of the individual is obtained.</StyledP>
      <StyledP>However there are some circumstances in which we must disclose an individual’s information such as : When we reasonably believe that an individual may be engaged in fraudulent, deceptive or unlawful activity that a governmental authority should be made aware of; As required by any law (including the Privacy Act); In order to sell our business (in that we may need to transfer Personal Information to a new owner); With third parties that provide services or handle transactions on our behalf.</StyledP>
      <StyledP>SKETCHSOFT may use third-pay service providers and/or data processors for specific purposes on behalf of and in accordance with our Privacy Policy. The data processors shall process the Personal Information at most as long as the term of the data processing contract concluded with them is valid and in force, or until they are required to keep your data under the applicable data retention laws.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[5] Data Retention</StyledH3>
      <StyledP>We will retain Personal Information for the period necessary to fulfil the purposes outlined in this Privacy Policy, including for the purposes of satisfying any legal, accounting, or reporting requirements, unless a longer retention period is required or permitted by law.</StyledP>
      <StyledP>Any data deemed no-longer relevant is deleted. SKETCHSOFT may keep your personal data for at least six years from the date of the last interaction for insurance and liability purposes.</StyledP>
      <StyledP>SKETHSOFT may keep staff and consultant personal data for at least seven years.</StyledP>
      <StyledP>SKETHCOSFT shall keep emails of clients and prospective clients for as long as it has a legitimate interest to do so.</StyledP>
      <StyledP>You can request details of retention periods for different aspects of your personal data by contacting us on the details below.</StyledP>
      <StyledP>If we have taken steps to anonymize your Personal Information (so that it can no longer be associated with you) we may use this for analytical, research and statistical purposes and to improve our products and software. Since this is no longer Personal Information and we may use this information indefinitely without further notice.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[6] Data Protection</StyledH3>
      <StyledP>We maintain reasonable administrative, technical and physical safeguards to protect the personal information we obtain through the SITE and SOFTWARE. However, no method of transmission over the internet, or method of electronic storage, is 100% secure. Therefore, while we make reasonable efforts to protect your personal information, we cannot guarantee its absolute security. It is your responsibility to keep your password secure, and we can&apos;t guarantee the security of your data and/or information transmitted to the PLATFORM. All transfers are at your own risk.</StyledP>
      <StyledP>We also limit access to your Personal Information to only those employees, agents, contractors and other third parties who need it for our business. They will only process your Personal Information on our instructions and are subject confidentiality obligations.</StyledP>
      <StyledP>We are not responsible for the privacy or security practices of any third party (including third parties that we are permitted to disclose an individual’s Personal Information to in accordance with this Privacy Policy or any applicable laws) unless otherwise required by the Privacy Act and the GDPR. The collection and use of an individual’s information by such third parties may be subject to separate privacy and security policies.</StyledP>
      <StyledP>If you suspect any misuse or loss, or unauthorised access to, your Personal Information, you should let us know immediately. We will document the facts relating to the breach, its effects and the corrective action taken, and investigate the cause of the breach and how to prevent similar situations in the future.</StyledP>
      <StyledP>We are not responsible for any loss, damage or claim arising out of the use of your Personal Information by others authorized to provide you with your Personal Information.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[7] Opt In and/or Out</StyledH3>
      <StyledP>We respect your privacy and give you an opportunity to opt in and/or out of receiving announcements of certain information. This may prevent us from offering you some or all of our SITE and/or SOFTWARE and may terminate their access to some or all of the SITE and/or SOFTWARE they access with or through us. They will be aware of this by the followings.</StyledP>
      <StyledNum>1. When you Opt In : Where relevant, the individual will have the right to choose to have information collected and/or receive information from us.</StyledNum>
      <StyledNum>2. When you Opt Out : Where relevant, you will have the right to choose to exclude yourself from some or all collection of information and/or receiving information from us.</StyledNum>
      <StyledP>If you believe that they have received information from us that you did not opt in or out to receive, you should contact us on the details below.</StyledP>
      <StyledP>
        To opt out of Google Analytics on your browser or device, please visit the link below and follow the instructions:
        <StyledA href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</StyledA>
        .
      </StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[8] Do Not Track</StyledH3>
      <StyledP>SKETCHSOFT does not currently take action to respond to &quot;Do Not Track&quot; signals from browsers. This is because a uniform standard to respond to these signals has not been developed at present.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[9] Complaints and Disputes</StyledH3>
      <StyledP>If you feel that your Privacy rights have been breached, please contact us first. Further, you can also contact and lodge a complaint with the local data protection authority, in particular in the Member State of your habitual residence, place of work or place of the alleged infringement.</StyledP>
      <StyledP>We may do things other than those set out in this Privacy Policy to comply the applicable privacy and data protection law, including Regulation of EU(GDPR). This Privacy Policy should not lead to the idea that we are not compliant with the applicable privacy and data protection law, including Regulation of EU(GDPR).</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[10] Children Policy</StyledH3>
      <StyledP>SKETCHSOFT does not knowingly collect Personal Information from children under the age of 16. If you are under the age of 16, you must ask permission from your parent or guardian to use this SITE and/or SOFTWARE. If you become aware that we have collected Personal Information about a child under the age of 16, please contact us as indicated in the &quot;Contact Us&quot; section below.</StyledP>
      <StyledP> </StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[11] Your Rights</StyledH3>
      <StyledP>You may request information on processing, rectification, erasure, and restriction of your personal data under the contact details of the data processor, except for mandatory data processing.</StyledP>
      <StyledP>You are able to request by the followings.</StyledP>
      <StyledNum>1. Access to your Personal Information (commonly known as a &quot;data subject access request&quot;). This enables you to receive a copy of the Personal Information we hold about you.</StyledNum>
      <StyledNum>2. Correction of the Personal Information that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new information you provide to us.</StyledNum>
      <StyledNum>3. Erasure of your Personal Information. This enables you to ask us to delete or remove Personal Information where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your Personal Information if you have successfully exercised your right to object to processing; if we may have processed your information unlawfully; if we are required to erase your Personal Information to comply with local law. However we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you. Under certain circumstances you may have the right to request from us the erasure of your account and Personal Information (right to be forgotten) and we may be obliged to erase such Personal Information. In such cases we will not be able to further provide you with our SITE and/or SOFTWARE.</StyledNum>
      <StyledNum>4. Object to processing of your Personal Information in specific circumstances as: where we are relying on a legitimate interest (or those of a third party); where there is something about your particular situation which makes you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your Personal Information for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your Personal Information which disregard your rights and freedoms.</StyledNum>
      <StyledNum>5. Restriction of processing of your Personal Information. In this case the respective Personal Information will be marked and may only be processed by us for certain purposes in the following scenarios: if you want us to establish the information’s accuracy; where our use of the information is unlawful but you do not want us to erase it; where you need us to hold the information even if we no longer need it; exercise or defend legal claims; when we need to verify whether we have overriding legitimate grounds to use it although you have objected to our use of your information.</StyledNum>
      <StyledNum>6. Receive or transfer of your Personal Information to you or to a third party if such transmission is technically feasible. We will provide your Personal Information to you or a third party of your choice, in a structured, commonly used, machine-readable format. This right only applies to automated information which you initially consented for us to use or if we used the information to perform a contract with you.</StyledNum>
      <StyledNum>7. Withdraw consent at any time if we are relying on consent to process your Personal Information. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide access to certain SITE and/or SOFTWARE to you. We will advise you if this is the case at the time you withdraw your consent.</StyledNum>
      <StyledP>You will not have to pay a fee to access your Personal Information (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</StyledP>
      <StyledP>We may need to request specific information from you to help us confirm your identity and ensure your right to access your Personal Information (or to exercise any of your other rights). This is a security measure to ensure that Personal Information is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</StyledP>
      <StyledP>We try to respond to all legitimate requests by electronic means, within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[12] Change of Policy</StyledH3>
      <StyledP>We reserve the right to change or modify the Privacy Policy occasionally to reflect changes in our privacy practices or applicable laws. We may, as permitted by law, make such changes without prior notice. We will publish those changes on this page and notice when it was most recently updated. You will have access to this page by the SITE and SOFTWARE. SKETCHSOFT encourages you to periodically review this Privacy Policy to be informed. Your use of the SITE and/or SOFTWARE will be governed by the current version of this Privacy Policy.</StyledP>
      <StyledP>&nbsp;</StyledP>
      <StyledH3>[13] Contacting</StyledH3>
      <StyledP>
        You can contact through our&nbsp;
        <StyledA href="https://support.feather.art/">support channels</StyledA>
        .
      </StyledP>
      <StyledP>
        Or write us at&nbsp;
        <StyledA href="mailto:support@sketchsoft3d.com">support@sketchsoft3d.com</StyledA>
      </StyledP>
    </Wrapper>
  );
}

export default PrivacyContents;

const Wrapper = styled.section`
`;

const StyledH3 = styled.h3`
  margin-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.875rem;

  letter-spacing: -0.02em;

  color: #21232B;
`;

const StyledH4 = styled.h4`
  margin-bottom: 1.5rem;
  font-weight: 600;
  font-size: 20px;
`;

const StyledNum = styled.p`
  margin-left: 1.25em;
  margin-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 140%;

  letter-spacing: -0.02em;
  text-indent: -1em;

  color: #21232B;
`;

const StyledP = styled.p`
  margin-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 140%;

  letter-spacing: -0.02em;

  color: #21232B;
`;

const StyledList = styled.p`
  margin-left: 2em;
  margin-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 140%;

  list-style-type: disc;
  display: list-item;
  letter-spacing: -0.02em;

  color: #21232B;
`;

const StyledA = styled.a`
  margin-bottom: 1.5rem;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 140%;
  text-decoration: none;
  border-bottom: 1px solid black;

  letter-spacing: -0.02em;

  color: #21232B;
`;
