import React, { useCallback, useRef, useState } from 'react';

interface UseInputOptions {
  parser?: (value: string) => string,
  validator?: (value: string) => boolean,
}

const useInput = (
  initialValue: string,
  {
    parser = value => value,
    validator = () => true,
  }: UseInputOptions,
) => {
  const parserRef = useRef(parser);
  const validatorRef = useRef(validator);

  validatorRef.current = validator;
  parserRef.current = parser;

  const valid = useRef(validatorRef.current(initialValue));

  const [state, setState] = useState(parserRef.current(initialValue));

  const update = useCallback((value: string | React.ChangeEvent<HTMLInputElement>) => {
    setState(() => {
      const stringValue = (typeof value === 'string' ? value : value.target.value) ?? '';
      const parsed = parserRef.current(stringValue);
      valid.current = validatorRef.current(parsed);

      return parsed;
    });
  }, []);

  return [state, update, valid.current] as const;
};

export default useInput;
