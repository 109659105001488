import React from 'react';
import styled from 'styled-components';

import ActionBox from './ActionBox';

import { ReactComponent as DrawPenIcon } from '../../assets/svgs/draw-with-pen.svg';
import { ReactComponent as DragFingerIcon } from '../../assets/svgs/drag-with-finger.svg';
import { ReactComponent as ShapeToolsIcon } from '../../assets/svgs/shape-with-tools.svg';

import drawPenImage from '../../assets/pngs/draw-with-pen.png';
import dragFingerImage from '../../assets/pngs/drag-with-finger.png';
import shapeToolsImage from '../../assets/pngs/shape-with-tools.png';
import Heading3 from '../common/Heading3';
import Heading5 from '../common/Heading5';
import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_MOBILE_VERTICAL_MAX,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';

export interface QuickStarterProps {
  className?: string;
}

function QuickStarter({ className }: QuickStarterProps) {
  return (
    <Wrapper className={className}>
      <Heading3>Quick starter for capturing imagination.</Heading3>
      <Heading5>Pen to draw lines. Fingers to look around.</Heading5>
      <Boxes>
        <StyledTopBox
          title="Draw,"
          subtitle="Start with a pen stroke."
          icon={<DrawPenIcon />}
          image={<img width="100%" src={drawPenImage} alt="no" />}
          description={'A 3D guide is extruded\nfrom the first pen stroke.'}
        />
        <StyledMiddleBox
          title="Rotate,"
          subtitle="Spin with fingers."
          icon={<DragFingerIcon />}
          image={<img width="100%" src={dragFingerImage} alt="no" />}
          description={'Changed view reveals\nthe guide for drawing on it.'}
        />
        <StyledBottomBox
          title="Create."
          subtitle="Shape further in detail."
          icon={<ShapeToolsIcon />}
          image={<img width="100%" src={shapeToolsImage} alt="no" />}
          description={'Brush colors and shapes are\nready for delicate expression.'}
        />
      </Boxes>
    </Wrapper>
  );
}

export default QuickStarter;

const Wrapper = styled.section`
  & > ${Heading3} {
    text-align: center;
    margin-bottom: 2rem;
  }

  & > ${Heading5} {
    text-align: center;
    margin-bottom: 3rem;
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    & > ${Heading3} {
      width: 100%;
      max-width: 470px;
      margin-left: auto;
      margin-right: auto;
      line-height: 110%;
    }
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    & > ${Heading3} {
      max-width: 350px;
    }
  }
`;

const Boxes = styled.div`
  display: flex;
  justify-content: space-between;
  & > * {
    &:nth-child(2) {
      margin-top: 7rem;
    }
    &:nth-child(3) {
      margin-top: 12rem;
    }
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    & > * {
      width: 325px;

      &:nth-child(2) {
        margin: 7rem 2rem 0;
      }
    }
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    display: block;
    & > * {
      width: 60%;
      min-width: 325px;

      &:nth-child(1) {
        margin-left: 8%;
      }
      &:nth-child(2) {
        margin: 4rem auto 0;
      }

      &:nth-child(3) {
        margin: 4rem 8% 0 auto;
      }
    }
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    & > * {
      min-width: 260px;

      &:nth-child(1) {
        margin-left: 0;
      }

      &:nth-child(3) {
        margin-right: 0;
      }
    }
  }
`;

const StyledTopBox = styled(ActionBox)`
  & > p {
    margin-right: 0.375rem;
  }
`;

const StyledMiddleBox = styled(ActionBox)`
`;

const StyledBottomBox = styled(ActionBox)`
  & > p {
    margin-left: 0.375rem;
  }
`;
