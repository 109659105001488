import styled from 'styled-components';
import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_MOBILE_VERTICAL_MAX, MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';

const Heading3 = styled.h3`
  font-weight: 700;
  font-size: 2.75rem;

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 2.05rem;
  }
`;

export default Heading3;
