import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

export interface GalleryItemProps {
  title: string,
  preview?: string,
  thumbnail: string,
  onClick?: () => void,
}

function GalleryItem({ title, thumbnail, preview = thumbnail, onClick }: GalleryItemProps) {
  const ref = useRef<HTMLImageElement>(null);
  const loaded = useRef(false);

  useEffect(() => {
    if (!ref.current) return () => {};
    const { current } = ref;

    const io = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting === false || loaded.current) return;
        const { target } = entry;
        if (target instanceof HTMLImageElement) {
          target.src = thumbnail;
        }
      });
    });

    io.observe(current);

    return () => {
      io.unobserve(current);
    };
  }, [thumbnail]);

  return (
    <Image ref={ref} draggable={false} title={title} alt={title} src={preview} onClick={onClick} />
  );
}

export default GalleryItem;

const Image = styled.img`
  object-fit: cover;
  user-select: none;
  border-radius: 24px;
  ${({ onClick }) => onClick && 'cursor: pointer;'}
`;
