import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';

import useToggle from '../hooks/useToggle';

import Heading3 from '../components/common/Heading3';
import PricingCards from '../components/pricing/PricingCards';
import ChangeNotice from '../components/pricing/ChangeNotice';
import SubscribeModal, { SubscribeModalProps } from '../components/pricing/SubscribeModal';
import subscribeEmail from '../utils/subscribeEmail';

function Pricing() {
  const navigate = useNavigate();
  const [isPending, togglePending] = useToggle(false);
  const [isSubscribeOpen, toggleSubscribeOpen] = useToggle(false);

  const handleDownload = useCallback(() => {
    navigate('/download');
  }, [navigate]);

  const handleSubscribe = useCallback<SubscribeModalProps['onSubmit']>(async ({ email, nickname }) => {
    if (isPending) return;
    togglePending();
    await subscribeEmail({ email, name: nickname });
    togglePending();
    toggleSubscribeOpen();
  }, [isPending, togglePending, toggleSubscribeOpen]);

  return (
    <>
      <Helmet>
        <title>Feather - Pricing</title>
        <meta
          name="description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta name="author" content="Sketchsoft Inc." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://feather.art/pricing" />
        <meta property="og:title" content="Feather - Pricing" />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/feather-image.jpg`} />
        <meta
          property="og:description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta property="og:site_name" content="Feather" />
      </Helmet>
      <StyledHeading3>
        FREEdom to create,
        <br />
        Premium to optimize.
      </StyledHeading3>
      <PricingCards
        onDownload={handleDownload}
        onSubscribe={toggleSubscribeOpen}
      />
      <StyledNotice onSubscribe={toggleSubscribeOpen} />
      {isSubscribeOpen && <SubscribeModal onSubmit={handleSubscribe} onClose={toggleSubscribeOpen} />}
    </>
  );
}

export default Pricing;

const StyledHeading3 = styled(Heading3)`
  margin: 8rem 0 5rem;
  text-align: center;
`;

const StyledNotice = styled(ChangeNotice)`
  margin: 5rem 0;
`;
