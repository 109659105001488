import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import Footer from './Footer';
import { MEDIA_MAX_MOBILE_HORIZONTAL_MAX } from '../constants/media';

export interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <Root>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </Root>
  );
}

export default Layout;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1180px;
  min-height: 100vh;
  margin: auto;
  padding: 0 2.5rem;

  & > main {
    flex: 1;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    padding: 0 1.5rem;
  }
`;
