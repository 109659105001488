import React from 'react';

export interface SketchfabViewerProps {
  className?: string;
  modelId: string;
}

function SketchfabViewer({ className, modelId }: SketchfabViewerProps) {
  return (
    <article className={className}>
      <iframe
        title={modelId}
        width="100%"
        height="100%"
        src={`https://sketchfab.com/models/${modelId}/embed?autostart=1`}
        allow="autoplay; fullscreen; xr-spatial-tracking"
      />
    </article>
  );
}

export default SketchfabViewer;
