import React from 'react';
import styled from 'styled-components';

import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_MOBILE_VERTICAL_MAX,
  MEDIA_MAX_TABLET_HORIZONTAL_WIDTH,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';

import SketchfabViewer from './SketchfabViewer';
import Modal from '../common/Modal';

export interface SketchfabViewerModalProps {
  modelId?: string;
  onClose: () => void;
}

function SketchfabViewerModal({ modelId, onClose }: SketchfabViewerModalProps) {
  if (!modelId) return null;

  return (
    <Modal open={!!modelId} onClose={onClose}>
      <StyledViewer modelId={modelId} />
    </Modal>
  );
}

export default SketchfabViewerModal;

const StyledViewer = styled(SketchfabViewer)`
  margin: 40px;
  width: 1140px;
  height: 641px;
  border-radius: 1.5rem;
  overflow: hidden;

  ${MEDIA_MAX_TABLET_HORIZONTAL_WIDTH} {
    width: 900px;
    height: 506px;
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    width: 700px;
    height: 410px;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    width: 400px;
    height: 241px;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    width: 320px;
    height: 180px;
  }
`;
