import React from 'react';
import styled from 'styled-components';
import {
  MEDIA_MAX_MOBILE_VERTICAL_MAX,
  MEDIA_MAX_TABLET_HORIZONTAL_WIDTH,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';

export interface FunctionListProps {
  className?: string;
  functions: string[];
}

function FunctionList({ className, functions }: FunctionListProps) {
  return (
    <Ul className={className}>
      {functions.map(func => (
        <Li key={func}>{func}</Li>
      ))}
    </Ul>
  );
}

export default FunctionList;

const Ul = styled.ul``;

const Li = styled.li`
  position: relative;
  padding-left: 1.2rem;
  line-height: 150%;
  font-size: 0.875rem;
  letter-spacing: -0.02em;

  &::before {
    content: '✓';
    position: absolute;
    top: 1px;
    left: 2px;
    transform: scaleX(1.35) scaleY(0.9);
    font-size: 1rem;
  }

  ${MEDIA_MAX_TABLET_HORIZONTAL_WIDTH} {
    font-size: 0.75rem;
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    font-size: 1.375rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 1rem;
  }
`;
