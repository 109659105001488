import React, { useEffect, useRef } from 'react';
import Vimeo from '@vimeo/player';

export interface VimeoPlayerProps {
  className?: string,
  url: string,
}

function VimeoPlayer({ className, url }: VimeoPlayerProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current: element } = ref;
    if (!element) return () => {};
    const player = new Vimeo(element, {
      url,
      loop: true,
      background: true,
      responsive: true,
    });

    const io = new IntersectionObserver(entries => {
      entries.forEach(async entry => {
        const isPaused = await player.getPaused();
        if (entry.isIntersecting && isPaused) {
          player.play();
        } else if (!entry.isIntersecting && !isPaused) {
          player.pause();
        }
      });
    });

    io.observe(element);

    return () => {
      player.destroy();
      io.unobserve(element);
    };
  });

  return (
    <div ref={ref} className={className} />
  );
}

export default VimeoPlayer;
