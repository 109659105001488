import React from 'react';
import styled from 'styled-components';

import { ReactComponent as IPad } from '../../assets/svgs/ipad-and-pencil.svg';
import { ReactComponent as Apple } from '../../assets/svgs/apple-logo.svg';
import Button from '../common/Button';

export interface IPadAppStoreProps {
  onAppStore: () => void;
}

function IPadAppStore({ onAppStore }: IPadAppStoreProps) {
  return (
    <Wrapper>
      <IPad />
      <Content>
        <StyledButton onClick={onAppStore}>
          <AppleLogo />
          Apple App Store
        </StyledButton>
        <p>For iPad with Apple Pencil</p>
      </Content>
    </Wrapper>
  );
}

export default IPadAppStore;

const Wrapper = styled.article`
  position: relative;
  display: inline-block;
  & > svg {
    width: 100%;
    height: auto;
  }
`;

const Content = styled.div`
  position: absolute;
  z-index: 1;
  top: 41%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  & > p {
    font-size: 0.75rem;
    color: #7B7B8B;
    line-height: 120%;
  }
`;

const StyledButton = styled(Button)`
  width: 186px;
  height: 46.5px;
  margin: 0 auto 1rem;
  font-size: 14.5px;
  padding-top: 14px;
`;

const AppleLogo = styled(Apple)`
  fill: white;
  margin-right: 5px;
  margin-bottom: 0px;
`;
