import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Artworks from '../components/product/Artworks';
import DescribeInterface from '../components/product/DescribeInterface';
import DottedDivider from '../components/product/DottedDivider';
import DownloadFeather from '../components/product/DownloadFeather';
import IntroVideo from '../components/product/IntroVideo';
import QuickStarter from '../components/product/QuickStarter';
import { MEDIA_MAX_MOBILE_HORIZONTAL_MAX } from '../constants/media';

function Product() {
  const navigate = useNavigate();

  const handleDownload = useCallback(() => {
    navigate('/download');
  }, [navigate]);

  const handleLearn = useCallback(() => {
    window.open(process.env.REACT_APP_SUPPORT_URL);
  }, []);

  const handleSee = useCallback(() => {
    navigate('/explore');
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Feather: 3D Sketchbook</title>
        <meta
          name="description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta name="author" content="Sketchsoft Inc." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://feather.art" />
        <meta property="og:title" content="Feather: 3D Sketchbook" />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/feather-image.jpg`} />
        <meta
          property="og:description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta property="og:site_name" content="Feather" />
      </Helmet>
      <StyledIntroVideo
        vimeoUrl={process.env.REACT_APP_PRODUCT_INTRO_VIDEO_VIMEO_URL ?? ''}
        fullVideoUrl={process.env.REACT_APP_PRODUCT_INTRO_VIDEO_FULL_URL ?? ''}
        onDownload={handleDownload}
      />
      <StyledDivider1 />
      <StyledQuickStarter />
      <StyledDescribeInterface onLearnMore={handleLearn} />
      <StyledArtwork onSeeMore={handleSee} />
      <StyledDivider2 />
      <StyledDownloadFeather onDownload={handleDownload} />
    </>
  );
}

export default Product;

const StyledIntroVideo = styled(IntroVideo)`
  margin: 3rem 0;
  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    margin-top: 20%;
  }
`;

const StyledDivider1 = styled(DottedDivider).attrs({ type: 1 })`
  text-align: center;
  margin: 13rem 0 8rem;
`;

const StyledQuickStarter = styled(QuickStarter)`
  margin-bottom: 7rem;
`;

const StyledDescribeInterface = styled(DescribeInterface)`
  margin-bottom: 10rem;
`;

const StyledArtwork = styled(Artworks)`
  margin-bottom: 9rem;
`;

const StyledDivider2 = styled(DottedDivider).attrs({ type: 2 })`
  text-align: center;
  margin-bottom: 17rem;
`;

const StyledDownloadFeather = styled(DownloadFeather)`
  margin-bottom: 18rem;
`;
