import React from 'react';
import styled from 'styled-components';
import themes from '../../constants/theme';

import Button from '../common/Button';

export interface SeeMoreProps {
  className?: string;
  onSeeMore: () => void;
}

function SeeMore({ className, onSeeMore }: SeeMoreProps) {
  return (
    <Wrapper className={className}>
      <p>For more Feather-works around the world, please visit our Sketchfab collection and social channels.</p>
      <DarkOnlyButton onClick={onSeeMore}>See more works</DarkOnlyButton>
    </Wrapper>
  );
}

export default SeeMore;

const DarkOnlyButton = styled(Button)`
  width: 172px;
  height: 57px;
  font-size: 18px;
  color: ${themes.dark.color};
  & svg {
    fill: none;

    & path[class='background'] {
      fill: ${themes.dark.color};
    }
  }
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    max-width: 400px;
    margin-bottom: 29px;
    line-height: 1.5rem;
    text-align: center;
  }
`;
