export const MOBILE_VERTICAL_WIDTH_MAX = 449;

export const MOBILE_HORIZONTAL_WIDTH_MIN = 450;

export const MOBILE_HORIZONTAL_WIDTH_MAX = 767;

export const TABLET_VERTICAL_WIDTH_MIN = 768;

export const TABLET_VERTICAL_WIDTH_MAX = 959;

export const TABLET_HORIZONTAL_WIDTH_MIN = 960;

export const TABLET_HORIZONTAL_WIDTH_MAX = 1179;

export const DESKTOP_WIDTH_MIN = 1180;

export function mediaMax(pixel: number) {
  return `@media screen and (max-width: ${pixel}px)`;
}

export const MEDIA_MAX_MOBILE_VERTICAL_MAX = mediaMax(MOBILE_VERTICAL_WIDTH_MAX);

export const MEDIA_MAX_MOBILE_HORIZONTAL_MAX = mediaMax(MOBILE_HORIZONTAL_WIDTH_MAX);

export const MEDIA_MAX_TABLET_VERTICAL_WIDTH = mediaMax(TABLET_VERTICAL_WIDTH_MAX);

export const MEDIA_MAX_TABLET_HORIZONTAL_WIDTH = mediaMax(TABLET_HORIZONTAL_WIDTH_MAX);
