const SKETCHFAB_API_BASE_URL = 'https://api.sketchfab.com';

export interface SketchFabModel {
  animationCount: number,
  categories: Array<{
    uid: string,
    name: string,
    slug: string,
    uri: string,
    icon: string,
    thumbnails: Array<{
      width: number,
      height: number,
      url: string,
    }>,
  }>,
  commentCount: number,
  createdAt: string,
  embedUrl: string,
  faceCount: number,
  isAgeRestricted: boolean,
  license: {
    uri: string,
    label: string,
    fullName: string,
    requirements: string,
    url: string,
    slug: string,
  } | null,
  likeCount: number,
  name: string,
  publishedAt: string,
  soundCount: number,
  staffpickedAt: string | null,
  tags: Array<{
    name: string,
    slug: string,
    uri: string
  }>,
  thumbnails: {
    images: Array<{
      uid: string,
      width: number,
      height: number,
      size: number,
      url: string,
    }>,
  },
  uid: string,
  uri: string,
  user: {
    uid: string,
    username: string,
    displayName: string,
    account: string,
    profileUrl: string,
    avatar: {
      uid: string,
      uri: string,
      images: Array<{
        width: number,
        height: number,
        size: number,
        url: string
      }>,
    },
    uri: string
  },
  vertexCount: number,
  viewCount: number,
  viewerUrl: string,
  isDownloadable: boolean,
  isProtected: boolean,
  description: string,
  price: null,
  collectedAt: string
}

async function getSketchfabModelsInCollection(
  uid: string,
  sort?: 'createdAt' | '-createdAt' | 'collectedAt' | '-collectedAt',
) {
  const response = await fetch(`${SKETCHFAB_API_BASE_URL}/v3/collections/${uid}/models?sort_by=${sort}`);
  const result = await response.json() as { results: Array<SketchFabModel> };

  return result;
}

export default getSketchfabModelsInCollection;
