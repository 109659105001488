/**
 * open new window with html a tag without window.open.
 * GA is collected 'link_click' event by clicking a tag.
 * so using a tag dinamically instead of pushing event directly
 */
function windowOpenWithATag(title: string, href: string) {
  const a = document.createElement('a');
  a.href = href;
  a.innerText = title;
  a.rel = 'noopener noreferrer';
  a.target = '_blank';
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export default windowOpenWithATag;
