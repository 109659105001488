import React from 'react';
import styled from 'styled-components';

import left1 from '../../assets/pngs/feather-web-left1.png';
import left2 from '../../assets/pngs/feather-web-left2.png';
import right1 from '../../assets/pngs/feather-web-right1.png';
import right2 from '../../assets/pngs/feather-web-right2.png';

import { ReactComponent as RightArrow } from '../../assets/svgs/right-arrow.svg';

import Heading3 from '../common/Heading3';
import Heading5 from '../common/Heading5';
import Button from '../common/Button';
import themes from '../../constants/theme';
import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_MOBILE_VERTICAL_MAX,
  MEDIA_MAX_TABLET_HORIZONTAL_WIDTH,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';

export interface ArtworksProps {
  className?: string;
  onSeeMore: () => void;
}

function Artworks({ className, onSeeMore }: ArtworksProps) {
  return (
    <Wrapper className={className}>
      <Images>
        <img alt="no" src={left1} />
        <img alt="no" src={right2} />
        <img alt="no" src={left2} />
        <img alt="no" src={right1} />
      </Images>
      <Contents>
        <Heading3>{'From doodles\nto artworks.'}</Heading3>
        <Heading5>{'Where and how far you use\nit is fully up to you.'}</Heading5>
        <Button onClick={onSeeMore}>
          See more in 3D
          <StyledArrow />
        </Button>
      </Contents>
    </Wrapper>
  );
}

export default Artworks;

const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23.5rem 0 8rem;

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    padding: 28.5rem 0;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    padding: 65% 0;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    padding: 18rem 0;
  }
`;

const Images = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  & > img {
    position: absolute;
    width: 335px;

    &:nth-child(1) {
      right: calc(100% - 458px);
      bottom: calc(100% - 311px);
    }

    &:nth-child(2) {
      left: calc(100% - 394px);
      bottom: calc(100% - 341px);
    }

    &:nth-child(3) {
      top: calc(100% - 429px);
      right: calc(100% - 340px);
    }

    &:nth-child(4) {
      top: calc(100% - 304px);
      left: calc(100% - 350px);
    }
  }

  ${MEDIA_MAX_TABLET_HORIZONTAL_WIDTH} {
    & > img {
      width: 280px;

      &:nth-child(1) {
        right: 60%;
      }

      &:nth-child(2) {
        left: 65%;
      }

      &:nth-child(3) {
        right: 68%;
      }

      &:nth-child(4) {
        left: 68%;
      }
    }
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    & > img {
      width: 510px;

      &:nth-child(1) {
        top: 0;
        left: 2%;
        right: initial;
        bottom: initial;
      }

      &:nth-child(2) {
        left: initial;
        right: 2%;
        bottom: 0;
      }

      &:nth-child(3) {
        display: none;
      }

      &:nth-child(4) {
        display: none;
      }
    }
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    & > img {
      width: 70%;
      max-width: 580px;
      min-width: 290px;

      &:nth-child(1) {
        left: 0;
      }

      &:nth-child(2) {
        right: 0;
      }
    }
  }
`;

const Contents = styled.div`
  position: relative;
  display: inline-block;
  text-align: center;
  white-space: pre;
  margin 0 0 0.25rem 0;

  & > ${Heading5} {
    margin: 1.5rem 0;
    line-height: 1.3;
  }

  & > button {
    width: 192px;
    height: 54px;
    margin: auto;
    color: ${themes.light.color};
    font-size: 1rem;

    & svg {
      fill: none;

      & path[class='background'] {
        fill: ${themes.light.color};
      }
    }
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    margin 1.25rem 0;
  }
`;

const StyledArrow = styled(RightArrow)`
  margin-left: 0.5rem;
`;
