import React from 'react';
import styled, { keyframes } from 'styled-components';

function LoadingDot() {
  return (
    <Wrapper>
      <Dot />
      <Dot />
      <Dot />
    </Wrapper>
  );
}

export default LoadingDot;

const Dot = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #EBEBEB;
`;

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-80%);
  }
  60% {
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  display: inline-flex;
  gap: 6px;
  overflow: visible !important;

  ${Dot}:nth-child(1) {
    animation: ${bounce} 1s ease-in-out 0s infinite;
  }

  ${Dot}:nth-child(2) {
    animation: ${bounce} 1s ease-in-out 0.15s infinite;
  }

  ${Dot}:nth-child(3) {
    animation: ${bounce} 1s ease-in-out 0.3s infinite;
  }
`;
