import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import { ThemeTypeContext } from '../context/theme';
import { ThemeType } from '../constants/theme';

export type UseSwitchThemeWithUrlOptions = {
  [key in ThemeType]: string[];
};

function useSwitchThemeWithUrl(options: UseSwitchThemeWithUrlOptions) {
  const theme = useContext(ThemeTypeContext);

  const optionRef = useRef(options);
  optionRef.current = options;

  const location = useLocation();

  useEffect(() => {
    const isLight = optionRef.current.light.some(url => location.pathname === url);
    if (isLight) theme.toggle('light');
    const isDark = optionRef.current.dark.some(url => location.pathname === url);
    if (isDark) theme.toggle('dark');
  }, [location, theme]);
}

export default useSwitchThemeWithUrl;
