import React from 'react';
import styled from 'styled-components';
import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_MOBILE_VERTICAL_MAX,
  MEDIA_MAX_TABLET_HORIZONTAL_WIDTH,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';

import DottedDivider from '../product/DottedDivider';

import EssentialCard from './EssentialCard';
import PlusCard from './PlusCard';
import TeamCard from './TeamCard';

export interface PricingCardsProps {
  onDownload: () => void;
  onSubscribe: () => void;
}

function PricingCards({ onDownload, onSubscribe }: PricingCardsProps) {
  return (
    <Wrapper>
      <EssentialCard onDownload={onDownload} />
      <StyledDivider type={2} />
      <PlusCard onSubscribe={onSubscribe} />
      <StyledDivider type={1} />
      <TeamCard onSubscribe={onSubscribe} />
    </Wrapper>
  );
}

export default PricingCards;

const Wrapper = styled.section`
  display: flex;
  justify-content: space-around;

  ${MEDIA_MAX_TABLET_HORIZONTAL_WIDTH} {
    & > article {
      width: 280px;
      height: 550px;
    }
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    flex-direction: column;
    align-items: center;
    gap: 50px;
    & > article {
      width: 520px;
      height: 910px;
    }
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    & > article {
      width: 360px;
      height: auto;
    }
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    & > article {
      width: 260px;
    }
  }
`;

const StyledDivider = styled(DottedDivider)`
  display: none;
  max-width: 356px;
  margin: 2rem auto;

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    display: block;
  }
`;
