import styled from 'styled-components';
import { MEDIA_MAX_MOBILE_VERTICAL_MAX } from '../../constants/media';

const Heading4 = styled.h4`
  font-weight: 600;
  font-size: 2rem;

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 1.7rem;
  }
`;

export default Heading4;
