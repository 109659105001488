import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Divider1 } from '../../assets/svgs/dotted-divider1.svg';
import { ReactComponent as Divider2 } from '../../assets/svgs/dotted-divider2.svg';

const DIVIDER_TYPE = {
  1: Divider1,
  2: Divider2,
} as const;

export interface DottedDividerProps {
  className?: string;
  type: keyof typeof DIVIDER_TYPE;
}

function DottedDivider({ className, type }: DottedDividerProps) {
  const Divider = DIVIDER_TYPE[type];

  return (
    <Wrapper className={className}>
      <Divider />
    </Wrapper>
  );
}

export default DottedDivider;

const Wrapper = styled.div`
  overflow: hidden;
  & > svg {
    fill: none;
    stroke: ${({ theme }) => theme.color};
  }
`;
