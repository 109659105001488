import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { MEDIA_MAX_MOBILE_HORIZONTAL_MAX, MEDIA_MAX_MOBILE_VERTICAL_MAX } from '../constants/media';

import useSketchFabModelsInCollection from '../hooks/useSketchFabModelsInCollection';

import Heading2 from '../components/common/Heading2';
import LoadingDot from '../components/common/LoadingDot';

import SketchfabGallery from '../components/explore/SketchfabGallery';
import SketchfabViewerModal from '../components/explore/SketchfabViewerModal';
import SeeMore from '../components/explore/SeeMore';

function Explore() {
  const models = useSketchFabModelsInCollection(
    process.env.REACT_APP_GALLERY_SKETCHFAB_COLLECTION_ID ?? '',
    '-collectedAt',
  );
  const [selectedModelId, setSelectedModelId] = useState('');

  const handleSeeMore = useCallback(() => {
    window.open(process.env.REACT_APP_GALLERY_SKETCHFAB_COLLECTION_URL);
  }, []);

  return (
    <>
      <Helmet>
        <title>Feather - Explore</title>
        <meta
          name="description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta name="author" content="Sketchsoft Inc." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://feather.art/explore" />
        <meta property="og:title" content="Feather - Explore" />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/feather-image.jpg`} />
        <meta
          property="og:description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta property="og:site_name" content="Feather" />
      </Helmet>
      <StyledHeading2>
        {'Made with Feather.\nStyles like no other.'}
      </StyledHeading2>
      {models ? (
        <SketchfabGallery
          models={models.map(model => ({
            uid: model.uid,
            title: model.name,
            preview: model.thumbnails.images[4].url,
            thumbnail: model.thumbnails.images[1].url,
          }))}
          onClick={setSelectedModelId}
        />
      ) : (
        <LoadingDotWrapper>
          <LoadingDot />
        </LoadingDotWrapper>
      )}
      <StyledSeeMore onSeeMore={handleSeeMore} />
      <SketchfabViewerModal modelId={selectedModelId} onClose={() => setSelectedModelId('')} />
    </>
  );
}

export default Explore;

const StyledHeading2 = styled(Heading2)`
  margin: 7.5rem 0;
  white-space: pre;
  text-align: center;
  line-height: 120%;

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    width: 100%;
    max-width: 440px;
    margin: 7.5rem auto;
    font-size: 2.5rem;
    white-space: initial;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    max-width: 350px;
  }
`;

const LoadingDotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
`;

const StyledSeeMore = styled(SeeMore)`
  margin: 10.25rem 0 15rem;
`;
