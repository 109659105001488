import React from 'react';

import styled from 'styled-components';
import ToolGuide from './ToolGuide';

import Heading3 from '../common/Heading3';
import Heading5 from '../common/Heading5';

import { ReactComponent as CrossArrow } from '../../assets/svgs/cross-arrow.svg';
import { ReactComponent as Draw } from '../../assets/svgs/draw.svg';
import { ReactComponent as Erase } from '../../assets/svgs/erase.svg';
import { ReactComponent as AR } from '../../assets/svgs/ar.svg';
import { ReactComponent as Export } from '../../assets/svgs/export.svg';
import { ReactComponent as Folder } from '../../assets/svgs/folder.svg';
import { ReactComponent as RightArrow } from '../../assets/svgs/right-arrow.svg';

import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_MOBILE_VERTICAL_MAX,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';
import Button from '../common/Button';
import themes from '../../constants/theme';

export interface DescribeInterfaceProps {
  className?: string;
  onLearnMore: () => void;
}

function DescribeInterface({ className, onLearnMore }: DescribeInterfaceProps) {
  return (
    <Wrapper className={className}>
      <Heading3>
        <span>Engineered by </span>
        <span>creative professionals.</span>
      </Heading3>
      <Heading5>
        {'Simple but polished interface.\nAs natural as real pen & paper.'}
      </Heading5>
      <Tools>
        <StyledTool icon={<CrossArrow />} guide={'Move, rotate, scale, or adjust \nproportion of drawing.'} />
        <StyledTool icon={<Draw />} guide={'Draw neatly with a mode for \nsmooth lines and circles.'} />
        <StyledTool icon={<Erase />} guide={'Rub out your strokes \npoint by point with an eraser.'} />
        <StyledTool icon={<AR />} guide={'Place your drawing in \naugmented reality.'} />
        <StyledTool icon={<Export />} guide={'Export files as OBJ or GLTF \nfor further workflow.'} />
        <StyledTool icon={<Folder />} guide={'Make folders in unlimited \ndepth to organize drawings.'} />
      </Tools>
      <ButtonArea>
        <Button onClick={onLearnMore}>
          Learn more
          <StyledArrow />
        </Button>
      </ButtonArea>
    </Wrapper>
  );
}

export default DescribeInterface;

const Wrapper = styled.section`
  & > ${Heading3} {
    margin-bottom: 1rem;
  }

  & > ${Heading5} {
    margin-bottom: 3rem;
    white-space: pre;
    line-height: 120%;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    & > ${Heading3}, & > ${Heading5} {
      text-align: center;
      & > span {
        display: block;
      }
    }
  }
`;

const Tools = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 30px;
  margin-bottom: 2.5rem;

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    grid-template-columns: 1fr 1fr;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    padding-left: 2%;
  }
`;

const StyledTool = styled(ToolGuide)`
  white-space: pre;

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    white-space: initial;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    & > div {
      width: 35px;
      height: 35px;

      margin-right: 0.5rem;

      & > svg {
        width: 20px;
        height: 20px;
      }
    }

    & > p {
      line-height: 1.3;
    }
  }
`;

const ButtonArea = styled.div`
  & > button {
    width: 172px;
    height: 57px;
    margin-left: auto;
    color: ${themes.light.color};
    font-size: 1rem;

    & svg {
      fill: none;

      & path[class='background'] {
        fill: ${themes.light.color};
      }
    }
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    & > button {
      margin-right: auto;
    }
  }
`;

const StyledArrow = styled(RightArrow)`
  margin-left: 0.5rem;
`;
