import React from 'react';
import { Helmet } from 'react-helmet';

import styled from 'styled-components';

import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_MOBILE_VERTICAL_MAX,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../constants/media';

import Heading2 from '../components/common/Heading2';
import TermsContents from '../components/policy/TermsContents';

function Terms() {
  return (
    <>
      <Helmet>
        <title>Feather - Terms of service</title>
        <meta
          name="description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta name="author" content="Sketchsoft Inc." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://feather.art/terms" />
        <meta property="og:title" content="Feather - Terms of service" />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/feather-image.jpg`} />
        <meta
          property="og:description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta property="og:site_name" content="Feather" />
      </Helmet>
      <Wrapper>
        <StyledHeading2>
          TERMS OF SERVICE
        </StyledHeading2>
        <StyledLastUpdate>
          <StyledLabel>Last Update</StyledLabel>
        &nbsp;: August 25, 2021
        </StyledLastUpdate>
        <TermsContents />
      </Wrapper>
    </>
  );
}

export default Terms;

const Wrapper = styled.div`
  padding: 0 5rem;
  margin-bottom: 25rem;


  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    padding: 0 2.75rem;
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    padding: 0 2rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    padding: 0;
  }
`;

const StyledHeading2 = styled(Heading2)`
  margin: 8rem 0 1.25rem 0;
  white-space: pre;
  line-height: 120%;

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    width: 100%;
    max-width: 440px;
    margin: 7.5rem auto;
    font-size: 2.5rem;
    white-space: initial;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    max-width: 350px;
  }
`;

const StyledLastUpdate = styled.div`
  font-size: 1.25rem;
  margin-bottom: 5rem;
`;

const StyledLabel = styled.div`
  display: inline-block;
  font-weight: 700;
`;
