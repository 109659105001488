import styled from 'styled-components';
import { MEDIA_MAX_MOBILE_VERTICAL_MAX } from '../../constants/media';

const Heading5 = styled.h5`
  font-weight: 600;
  font-size: 1.25rem;

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 1rem;
  }
`;

export default Heading5;
