import React, { useCallback } from 'react';
import styled from 'styled-components';

import { ReactComponent as Check } from '../../assets/svgs/green-check.svg';

export interface CheckboxProps {
  className?: string;
  checked: boolean;
  onToggle: (value: boolean) => void;
}

function Checkbox({ className, checked, onToggle }: CheckboxProps) {
  const hnadleToggle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onToggle(e.target.checked);
  }, [onToggle]);

  return (
    <Label className={className}>
      <input type="checkbox" checked={checked} onChange={hnadleToggle} />
      <Check />
    </Label>
  );
}

export default Checkbox;

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid ${({ theme }) => theme.color};

  & > svg {
    position: absolute;
    top: -7px;
    left: 1px;
    display: none;
    width: 16px;
    height: 22px;
    fill: none;
  }

  & > input {
    display: none;
  }

  & > input:checked + svg {
    display: inline;
  }
`;
