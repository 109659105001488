import { css } from 'styled-components';

const resetStyle = css`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  html {
    font-size: 16px;
  }
  body {
    line-height: 1;
    font-family: 'Pretendard', sans-serif;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  button {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    background: transparent;
  }
  input {
    font-size: 16px;
    outline: none;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:visited {
      color: inherit;
    }
  }
`;

export default resetStyle;
