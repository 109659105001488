import React from 'react';
import styled from 'styled-components';
import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_MOBILE_VERTICAL_MAX, MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';

import Heading4 from '../common/Heading4';

export interface ActionBoxProps {
  className?: string;
  title: string;
  subtitle: string;
  icon: React.ReactElement;
  image: React.ReactElement;
  description: string;
}

function ActionBox({ className, title, subtitle, icon, image, description }: ActionBoxProps) {
  return (
    <Wrapper className={className}>
      <Row>
        <Titles>
          <Heading4>{title}</Heading4>
          <p>{subtitle}</p>
        </Titles>
        {icon}
      </Row>
      {image}
      <Description>{description}</Description>
    </Wrapper>
  );
}

export default ActionBox;

const Wrapper = styled.article`
  width: 280px;
  & > *:nth-last-child(n + 2) {
    margin-bottom: 0.5625rem;
  }
`;

const Titles = styled.div`
  & > ${Heading4} {
    margin-bottom: 0.3rem;
  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    & > p {
      font-size: 0.875rem;
    }
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    & > p {
      font-size: 1.5rem;
    }
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    & > p {
      font-size: 1.3rem;
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & svg {
    fill: none;
  }
`;

const Description = styled.p`
  color: #99999C;
  white-space: pre;
  line-height: 140%;
  text-align: center;

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    font-size: 1.25rem;
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 1rem;
  }
`;
