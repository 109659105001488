import styled from 'styled-components';
import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_MOBILE_VERTICAL_MAX,
  MEDIA_MAX_TABLET_HORIZONTAL_WIDTH,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';

const Heading2 = styled.h2`
  font-weight: 700;
  font-size: 3.5rem;

  ${MEDIA_MAX_TABLET_HORIZONTAL_WIDTH} {
    font-size: 3rem;

  }

  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
  }

  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
  }

  ${MEDIA_MAX_MOBILE_VERTICAL_MAX} {
    font-size: 2.3rem;
  }
`;

export default Heading2;
