import React, { useRef } from 'react';
import styled from 'styled-components';

import VimeoPlayer, { VimeoPlayerProps } from './VimeoPlayer';

import { ReactComponent as Border } from '../../assets/svgs/ipad-border.svg';
import { ReactComponent as RightArrow } from '../../assets/svgs/right-short-arrow.svg';

export interface IPadVideoProps {
  className?: string;
  vimeoUrl: VimeoPlayerProps['url'];
  fullVideoUrl?: string;
}

function IPadVideo({ className, vimeoUrl, fullVideoUrl }: IPadVideoProps) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={ref} className={className}>
      <Border />
      <StyledPlayer url={vimeoUrl} />
      <FullVideo target="_blank" href={fullVideoUrl}>
        Watch full video
        <RightArrow />
      </FullVideo>
    </Wrapper>
  );
}

export default IPadVideo;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  & > svg {
    width: 100%;
    height: auto;
  }
`;

const StyledPlayer = styled(VimeoPlayer)`
  position: absolute;
  top: 4%;
  left: 5%;
  right: 5%;
  overflow: hidden;
  border-radius: 0.5rem;
`;

const FullVideo = styled.a`
  position: absolute;
  right: 2%;
  bottom: 0;
  display: flex;
  align-items: center;
  color: #99999C;

  &:visited {
    color: #99999C;
  }

  & > svg {
    margin-left: 0.5em;
  }
`;
