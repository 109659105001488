import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Galaxy } from '../../assets/svgs/galaxy-tab-and-pen.svg';
import { ReactComponent as Chrome } from '../../assets/svgs/chrome-icon.svg';

import Button from '../common/Button';

export interface GalaxyChromeProps {
  onWebAppInstall: () => void;
}

function GalaxyChrome({ onWebAppInstall }: GalaxyChromeProps) {
  return (
    <Wrapper>
      <Galaxy />
      <Content>
        <StyledButton onClick={onWebAppInstall}>
          <ChromeLogo />
          Web App Install
        </StyledButton>
        <p>For other devices</p>
        <p>(Chrome browser recommended)</p>
      </Content>
    </Wrapper>
  );
}

export default GalaxyChrome;

const Wrapper = styled.article`
  position: relative;
  display: inline-block;
  & > svg {
    width: 100%;
    height: auto;
  }
`;

const Content = styled.div`
  position: absolute;
  z-index: 1;
  top: 38%;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;

  & > p {
    font-size: 0.75rem;
    color: #7B7B8B;
    line-height: 120%;
  }
`;

const StyledButton = styled(Button)`
  width: 186px;
  height: 46.5px;
  font-size: 14.5px;
  margin: 0 auto 1rem;
  padding-top: 15px;

  color: ${({ theme }) => theme.color};
  & > svg:last-child {
    fill: #D9D9E3;

    & path[class='background'] {
      fill: ${({ theme }) => theme.color};
    }
  }
`;

const ChromeLogo = styled(Chrome)`
  fill: ${({ theme }) => theme.color};
  margin-right: 5px;
  margin-bottom: -1px;
`;
