import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import windowOpenWithATag from '../utils/windowOpenWithATag';

import DownloadGuide from '../components/download/DownloadGuide';
import DownloadByDevice from '../components/download/DownloadByDevice';
import DeviceSupport from '../components/download/DeviceSupport';

function Download() {
  const handleAppStore = useCallback(() => {
    const target = process.env.REACT_APP_FEATHER_APP_STORE_URL;
    if (target) windowOpenWithATag('Apple App store', target);
  }, []);

  const handleWebAppInstall = useCallback(() => {
    const target = process.env.REACT_APP_FEATHER_WEB_APP_INSTALL_URL;
    if (target) windowOpenWithATag('Web App Install', target);
  }, []);

  return (
    <>
      <Helmet>
        <title>Feather - Download</title>
        <meta
          name="description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta name="author" content="Sketchsoft Inc." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://feather.art/download" />
        <meta property="og:title" content="Feather - Download" />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/feather-image.jpg`} />
        <meta
          property="og:description"
          content="Feather is a 3D drawing app for
          quickly capturing your imagination in 3D.
          With its simple but polished interface, everyone can draw, rotate, and create."
        />
        <meta property="og:site_name" content="Feather" />
      </Helmet>
      <StyledDownloadGuide />
      <DownloadByDevice
        onAppStore={handleAppStore}
        onWebAppInstall={handleWebAppInstall}
      />
      <StyledDeviceSupport supportUrl={process.env.REACT_APP_SUPPORT_URL ?? ''} />
    </>
  );
}

export default Download;

const StyledDownloadGuide = styled(DownloadGuide)`
  margin: 8rem 0 4rem;
`;

const StyledDeviceSupport = styled(DeviceSupport)`
  margin: 5rem 0;
`;
