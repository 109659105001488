import React from 'react';
import styled from 'styled-components';

import GalleryGrid from './GalleryGrid';
import GalleryItem, { GalleryItemProps } from './GalleryItem';

export type GalleryModel = Omit<GalleryItemProps, 'onClick'> & { uid: string };

export interface SketchfabGalleryProps {
  className?: string;
  models: Array<GalleryModel>;
  onClick: (uid: GalleryModel['uid']) => void;
}

function SketchfabGallery({ className, models, onClick }: SketchfabGalleryProps) {
  return (
    <Wrapper className={className}>
      <GalleryGrid>
        {models.map(model => (
          <GalleryItem
            key={model.uid}
            title={model.title}
            preview={model.preview}
            thumbnail={model.thumbnail}
            onClick={() => onClick(model.uid)}
          />
        ))}
      </GalleryGrid>
      <Gradient />
    </Wrapper>
  );
}

export default SketchfabGallery;

const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
`;

const Gradient = styled.div`
  position: absolute;
  height: 250px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(14, 14, 14, 0) 0%, #0E0E0E 68.99%);
`;
