import React from 'react';
import styled from 'styled-components';

export interface DeviceSupportProps {
  className?: string;
  supportUrl: string;
}

function DeviceSupport({ className, supportUrl }: DeviceSupportProps) {
  return (
    <Wrapper className={className}>
      <p>We will continue to update Feather for broader device support (including mobile and desktop).</p>
      <p>
        For more information or help, please visit
        {' '}
        <a href={supportUrl} target="_blank" rel="noreferrer">
          Support
        </a>
        .
      </p>
    </Wrapper>
  );
}

export default DeviceSupport;

const Wrapper = styled.section`
  padding: 0 4rem;
  text-align: center;
  line-height: 140%;
  font-size: 0.875rem;

  & a {
    border-bottom: 1px solid black;
  }
`;
