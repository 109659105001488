import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  MEDIA_MAX_MOBILE_HORIZONTAL_MAX,
  MEDIA_MAX_TABLET_HORIZONTAL_WIDTH,
  MEDIA_MAX_TABLET_VERTICAL_WIDTH,
} from '../../constants/media';

const GALLERY_ITEM_MAX = 20;

interface GalleryGridProps {
  className?: string;
  children: React.ReactNode;
}

function GalleryGrid({ className, children }: GalleryGridProps) {
  const spliced = children instanceof Array ? children.slice(0, 20) : children;

  return (
    <Wrapper className={className}>
      {spliced}
    </Wrapper>
  );
}

const SMALL_SIZE_INDEX = [1, 4, 5, 9, 10, 14, 15, 19];

const MEDIA_DESKTOP_SIZE = {
  LARGE: {
    width: 520,
    height: 390,
  },
  SMALL: {
    width: 520,
    height: 292,
  },
};

const MEDIA_TABLET_HORIZONTAL_SIZE = {
  LARGE: {
    width: 416,
    height: 312,
  },
  SMALL: {
    width: 416,
    height: 233,
  },
};

const MEDIA_TABLET_VERTICAL_SIZE = {
  LARGE: {
    width: 325,
    height: 243,
  },
  SMALL: {
    width: 325,
    height: 182,
  },
};

const createNthChildSize = ({ index, width, height }: {index: number, width: number, height: number}) => css`
  &:nth-child(${index}) {
    width: ${width}px;
    height: ${height}px;
  }
`;

const itemStyles = Array.from({ length: GALLERY_ITEM_MAX }).reduce<
  Record<'desktop' | 'horizontalTablet' | 'verticalTablet', FlattenSimpleInterpolation[]>
>((prev, _, index) => {
  const target = SMALL_SIZE_INDEX.includes(index) ? 'SMALL' : 'LARGE';
  prev.desktop.push(createNthChildSize({ index: index + 1, ...MEDIA_DESKTOP_SIZE[target] }));
  prev.horizontalTablet.push(createNthChildSize({ index: index + 1, ...MEDIA_TABLET_HORIZONTAL_SIZE[target] }));
  prev.verticalTablet.push(createNthChildSize({ index: index + 1, ...MEDIA_TABLET_VERTICAL_SIZE[target] }));
  return prev;
}, {
  desktop: [],
  horizontalTablet: [],
  verticalTablet: [],
});

const Wrapper = styled.article`
  display: inline-block;
  width: 1090px;
  & > * {
    &:nth-last-child(n+3) {
      margin-bottom: 50px;
    }
    &:nth-child(2n-1) {
      float: left;
      margin-right: 50px;
    }
    &:nth-child(2n) {
    }
    ${itemStyles.desktop}
  }
  ${MEDIA_MAX_TABLET_HORIZONTAL_WIDTH} {
    width: 872px;
    & > * {
      &:nth-last-child(n+3) {
        margin-bottom: 40px;
      }
      &:nth-child(2n-1) {
        margin-right: 40px;
      }
      ${itemStyles.horizontalTablet}
    }
  }
  ${MEDIA_MAX_TABLET_VERTICAL_WIDTH} {
    width: 681px;
    & > * {
      &:nth-last-child(n+3) {
        margin-bottom: 31px;
      }
      &:nth-child(2n-1) {
        margin-right: 31px;
      }
      ${itemStyles.verticalTablet}
    }
  }
  ${MEDIA_MAX_MOBILE_HORIZONTAL_MAX} {
    width: 100%;
    & > * {
      margin: 0 0 50px 0;
      &:nth-child(n) {
        float: none;
        width: 100%;
        height: auto;
      }
    }
  }

  &::after {
    content: " ";
    float: both;
  }
`;

export default GalleryGrid;
