const SUBSCRIBE_API_URL = 'https://api.feather.art/addSubscriber';

async function subscribeEmail({ email, name }: {email: string, name?: string}) {
  const body = { email, name };

  return fetch(SUBSCRIBE_API_URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}

export default subscribeEmail;
