import React, { useCallback } from 'react';
import styled from 'styled-components';

export interface InputProps {
  className?: string;
  placeholder?: string;
  value: string;
  onChange: (value: InputProps['value']) => void;
  valid: boolean;
}

function Input({ className, placeholder, value, onChange, valid }: InputProps) {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <StyledInput
      className={className}
      type="text"
      value={value}
      onChange={handleChange}
      valid={valid}
      placeholder={placeholder}
    />
  );
}

export default Input;

const StyledInput = styled.input<{valid: boolean}>`
  width: 100%;
  height: 3.5rem;
  padding: 1rem 2.5rem;
  border: 1px ${({ valid, theme }) => `${valid ? 'solid' : 'dashed'} ${theme.color}`};
  border-radius: 0.5rem;

  &::placeholder {
    color: #B3B3B6;
    font-size: 1rem;
  }
`;
