import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import GA from './utils/ga';
import metapixel from './utils/metapixel';
import reportWebVitals from './reportWebVitals';

// initialize GA
const gaId = process.env.REACT_APP_GA_ID;
if (gaId) {
  GA.init(gaId);
}

// initialize metapixel
const metapixelId = process.env.REACT_APP_METAPIXEL_ID;
if (metapixelId) {
  metapixel.load(metapixelId);
}

// initialize page
const divRoot = document.getElementById('root');
if (divRoot) {
  const reactRoot = ReactDOM.createRoot(divRoot);
  reactRoot.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

reportWebVitals();
