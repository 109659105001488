import { useState, useEffect } from 'react';

import getSketchfabModelsInCollection, { SketchFabModel } from '../utils/getSketchfabModelsInCollection';

type Parameter = Parameters<typeof getSketchfabModelsInCollection>

function useSketchFabModelsInCollection(uid: Parameter[0], sort?: Parameter[1]) {
  const [state, setState] = useState<SketchFabModel[] | null>(null);

  useEffect(() => {
    (async () => {
      const { results } = await getSketchfabModelsInCollection(uid, sort);

      setState(results);
    })();
  }, [uid, sort]);

  return state;
}

export default useSketchFabModelsInCollection;
